import React from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { Screenshot } from './styles';
import { BlurCircle } from '../../../../styles/common.styles';
import { endpoints } from '../../../../utils/endpoints';
import { useCheckMobileScreen } from '../../../../hooks';

const HeroSection: React.FC = () => {
	const isMobile = useCheckMobileScreen();

	return (
		<Stack
			spacing={isMobile ? '60px' : '100px'}
			id={'hero-section'}
			pt={isMobile ? '80px' : '100px'}
			pb={isMobile ? '100px' : '180px'}
		>
			<Stack spacing={'40px'} alignItems={'center'}>
				<Stack spacing={'28px'} alignItems={'center'}>
					<Typography
						variant={'h2'}
						display={'inline-block'}
						textAlign={'center'}
						className={!isMobile ? 'animate__animated animate__fadeInDown animate__slow animate__delay-1s' : undefined}
					>
						{`Your Trusted Navigator `}

						<Typography
							variant={'h2'}
							display={isMobile ? 'inline' : undefined}
							color={'text.secondary'}
							className={!isMobile ? 'animate__animated animate__fadeInDown animate__delay-2s' : undefined}
						>
							in the Secondary Market
						</Typography>
					</Typography>

					<Typography
						variant={'body1'}
						textAlign={isMobile ? 'center' : undefined}
						className={!isMobile ? 'animate__animated animate__fadeIn animate__delay-3s' : undefined}
					>
						Reliable Private Market Data and Trading for Shareholders and Investors
					</Typography>
				</Stack>
				<Button
					className={!isMobile ? 'animate__animated animate__fadeIn animate__delay-4s' : undefined}
					variant="contained"
					size={'large'}
					sx={{ width: 200 }}
					href={endpoints.appSignup}
					target={'_blank'}
				>
					start now
				</Button>
			</Stack>
			<Box>
				<Grid
					container
					spacing={3}
					className={!isMobile ? 'animate__animated animate__fadeIn animate__delay-4s' : undefined}
					sx={{ position: 'relative' }}
				>
					<Grid item xs={12}>
						<Screenshot />
					</Grid>
					<BlurCircle />
				</Grid>
			</Box>
		</Stack>
	);
};

export default HeroSection;
