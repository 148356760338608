import { CardContent, Stack, SvgIcon, Typography } from '@mui/material';
import { MonetizationOn } from '@mui/icons-material';
import React from 'react';
import { CompanyCardContainer, CompanyCardHeader } from './style';

export interface CompanyCardProps {
	companyName: string;
	sectorName: string;
	sectorIcon: any;
	valuation: string;
	elevation?: number;
}

const CompanyCard: React.FC<CompanyCardProps> = (props) => {
	const { companyName, sectorName, sectorIcon, valuation, elevation = 6 } = props;

	return (
		<CompanyCardContainer variant={'elevation'} elevation={elevation} sx={{ boxShadow: 1 }}>
			<CompanyCardHeader title={companyName} />
			<CardContent>
				<Stack spacing={1}>
					<Stack spacing={1} direction={'row'} alignItems={'center'}>
						<SvgIcon component={sectorIcon} sx={{ fontSize: 16, color: 'text.secondary' }} />
						<Typography variant="body2" color={'text.secondary'}>
							{sectorName}
						</Typography>
					</Stack>
					<Stack spacing={1} direction={'row'} alignItems={'center'}>
						<MonetizationOn sx={{ fontSize: 16, color: 'primary.main' }} />
						<Typography variant="body2" color={'primary.main'}>
							{valuation}
						</Typography>
					</Stack>
				</Stack>
			</CardContent>
		</CompanyCardContainer>
	);
};

export default CompanyCard;
