import { Box, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import StatisticItem from './components/StatisticItem';
import { useCheckMobileScreen } from '../../../../hooks';

const StatisticsSection: React.FC = () => {
	const isMobile = useCheckMobileScreen();

	return (
		<Paper elevation={1} id={'statistics-section'} sx={{ boxShadow: 'none' }}>
			<Container>
				<Stack
					spacing={isMobile ? 4 : '40px'}
					pt={isMobile ? '36px' : '42px'}
					pb={isMobile ? '36px' : '42px'}
					alignItems={'center'}
					justifyContent={'center'}
				>
					<Typography variant={'body1'} textAlign={isMobile ? 'center' : undefined}>
						Become a ShareWeller and start leveraging the private markets today
					</Typography>
					<Box width={'100%'}>
						{isMobile ? (
							<Stack direction={'column'} spacing={'20px'}>
								<StatisticItem value={'50,000'} title={'Active Users'} />
								<StatisticItem
									value={'$800bn'}
									title={'Estimated Total Market Cap of Transacted Companies'}
									sx={{ flex: 1, marginLeft: 16, marginRight: 16 }}
								/>
								<StatisticItem value={'300+'} title={'Companies Served'} />
							</Stack>
						) : (
							<Grid container spacing={3}>
								<Grid item xs={1} />
								<Grid item xs={10}>
									<Stack direction={'row'}>
										<StatisticItem value={'50,000'} title={'Active Users'} />
										<StatisticItem
											value={'$800bn'}
											title={'Estimated Total Market Cap of Transacted Companies'}
											sx={{ flex: 1, marginLeft: 16, marginRight: 16 }}
										/>
										<StatisticItem value={'300+'} title={'Companies Served'} />
									</Stack>
								</Grid>
								<Grid item xs={1} />
							</Grid>
						)}
					</Box>
				</Stack>
			</Container>
		</Paper>
	);
};

export default StatisticsSection;
