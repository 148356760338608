import { Box, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import { FormatQuote } from '@mui/icons-material';
import { useCheckMobileScreen } from '../../../../hooks';

const InsightSection = () => {
	const isMobile = useCheckMobileScreen();

	return (
		<Paper elevation={1} sx={{ boxShadow: 'none' }} id={'insight-section'}>
			<Container>
				<Box pt={isMobile ? 3 : undefined} pb={isMobile ? 3 : undefined} position={isMobile ? 'relative' : undefined}>
					<Grid container spacing={3} height={isMobile ? 246 : undefined}>
						{!isMobile && (
							<Grid item xs={5}>
								<Stack pt={'60px'}>
									<img src={'/img/insight.png'} alt={'Derek Weber, Founder'} />
								</Stack>
							</Grid>
						)}
						{isMobile && (
							<Stack width={150} position={'absolute'} left={-24} bottom={0}>
								<img src={'/img/insight.png'} alt={'Derek Weber, Founder'} />
							</Stack>
						)}
						<Grid item xs={isMobile ? 3 : 1} />
						<Grid item xs={isMobile ? 9 : 5}>
							<Stack justifyContent={'center'} height={'100%'}>
								<Stack spacing={1} direction={'row'} height={isMobile ? '100%' : undefined}>
									<FormatQuote color="primary" sx={{ fontSize: isMobile ? 20 : 53 }} />
									<Stack
										spacing={2}
										alignItems={'flex-end'}
										pt={isMobile ? 1 : '30px'}
										height={isMobile ? '100%' : undefined}
										justifyContent={isMobile ? 'space-between' : undefined}
									>
										<Typography variant={'h5'} paragraph fontWeight={300}>
											We leverage our experience and employ a process designed to optimize value, terms & conditions for
											our clients.
										</Typography>
										<Typography color={'text.secondary'}>Derek Weber, Founder</Typography>
									</Stack>
								</Stack>
							</Stack>
						</Grid>
						{!isMobile && <Grid item xs={1} />}
					</Grid>
				</Box>
			</Container>
		</Paper>
	);
};

export default InsightSection;
