import * as React from 'react';
import { SVGProps } from 'react';
import { useCheckMobileScreen } from '../../../../../../hooks';
const SecondaryMarketData = (props: SVGProps<SVGSVGElement>) => {
	const isMobile = useCheckMobileScreen();

	if (isMobile) {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width={130} height={140} fill="none" {...props}>
				<path
					fill="url(#a)"
					d="M116.008 60.14c10.99-9.573 12.532-.464 13.276-.317 0 0 .326 2.201 0 10.226l-107.13 61.979V88.414C34.327 66.55 42.39 51.278 50.327 58.322c13.738 12.201 20.426 20.396 36.765 3.465 9.672-10.023 15.719 9.843 28.916-1.648Z"
					opacity={0.3}
				/>
				<path
					fill="url(#b)"
					d="M101.854 67.837c10.99-9.572 12.532-.463 13.275-.316 0 0 .327 2.2 0 10.226L8 139.726V96.112c17.34-25.239 28.77-20.543 38.679-16.898 9.706 3.567 13.264-3.882 25.55-14.504 10.11-8.748 16.428 14.606 29.625 3.127Z"
					opacity={0.3}
				/>
				<path
					fill="#AB47BC"
					d="M43.12 57.769c0-.903.53-1.93 1.172-2.303.642-.372 1.17.045 1.17.948 0 .903-.529 1.93-1.17 2.303-.653.372-1.171-.045-1.171-.948Z"
				/>
				<path
					fill="#CE93D8"
					d="M74.176 63.841c0-.869.54-1.885 1.216-2.269.676-.383 1.217 0 1.217.87 0 .869-.541 1.885-1.217 2.268-.664.384-1.216 0-1.216-.869Z"
				/>
				<path fill="#fff" d="M50.609 0 29.822 12.032v40.95l20.787-12.02V0Z" opacity={0.07} />
				<path
					fill="url(#c)"
					d="M54.662 3.521 33.876 15.554v40.95l8.986-5.192 1.407 1.151 1.408-2.788 8.985-5.192V3.521Z"
					opacity={0.3}
				/>
				<path
					fill="#fff"
					d="M36.601 28.32c0 1.31.191 2.506.552 3.51.36 1.005.89 1.795 1.554 2.337a3.62 3.62 0 0 0 2.33.813c.868 0 1.813-.282 2.748-.835v-9.99l-7.184 4.165Z"
					opacity={0.1}
				/>
				<path
					fill="#fff"
					d="M43.796 34.144c1.261-.734 2.5-1.908 3.592-3.42a19.51 19.51 0 0 0 2.635-5.181c.63-1.885.969-3.804.969-5.553 0-1.75-.338-3.285-.969-4.436l-6.227 8.6v9.99Z"
					opacity={0.3}
				/>
				<path
					fill="#AB47BC"
					d="M36.601 28.32c0-2.2.518-4.639 1.486-6.942.969-2.302 2.32-4.334 3.84-5.79 1.532-1.456 3.153-2.246 4.617-2.246s2.68.779 3.48 2.223l-6.228 8.601-7.195 4.154Z"
				/>
				<path
					fill="#fff"
					d="M51.949 34.584 39.28 41.909v1.535l12.668-7.325v-1.535ZM51.949 37.587l-15.36 8.883v1.535l15.371-8.883-.011-1.535ZM51.96 40.578l-9.954 5.757v1.535l9.954-5.757v-1.535Z"
					opacity={0.15}
				/>
				<path fill="#fff" d="M81.788 6.118 61.002 18.151V59.1L81.787 47.08V6.118Z" opacity={0.07} />
				<path
					fill="url(#d)"
					d="M85.797 9.673 65.01 21.693v40.951l8.975-5.18 1.407 1.15 1.419-2.787 8.986-5.204V9.673Z"
					opacity={0.3}
				/>
				<path
					fill="#fff"
					d="M67.724 34.46c0 1.75.338 3.285.968 4.436.631 1.151 1.543 1.885 2.635 2.133l3.592-10.734-7.195 4.165Z"
					opacity={0.1}
				/>
				<path
					fill="#fff"
					d="M71.316 41.03c1.374.304 2.961-.181 4.538-1.366 1.565-1.197 3.029-3.036 4.144-5.238l-5.09-4.12-3.592 10.724Z"
					opacity={0.3}
				/>
				<path
					fill="#CE93D8"
					d="M67.724 34.46c0-1.648.293-3.431.845-5.203a19.64 19.64 0 0 1 2.342-4.955c.98-1.479 2.117-2.698 3.288-3.544 1.182-.847 2.364-1.287 3.457-1.287 1.092 0 2.06.429 2.803 1.264.755.835 1.261 2.043 1.498 3.521.237 1.48.169 3.172-.169 4.944a19.556 19.556 0 0 1-1.801 5.204l-5.09-4.12-7.173 4.176Z"
				/>
				<path
					fill="#fff"
					d="m83.083 40.725-14.38 8.318v1.535l14.38-8.318v-1.535ZM83.083 43.727l-14.931 8.647v1.535l14.93-8.646v-1.535ZM83.083 46.73l-9.954 5.756v1.535l9.954-5.756V46.73Z"
					opacity={0.15}
				/>
				<defs>
					<linearGradient id="a" x1={56.22} x2={69.922} y1={33.82} y2={77.252} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<linearGradient id="b" x1={50.304} x2={58.088} y1={52.527} y2={87.677} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<linearGradient id="c" x1={44.269} x2={44.269} y1={9.254} y2={64.403} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" stopOpacity={0} />
						<stop offset={1} stopColor="#fff" />
					</linearGradient>
					<linearGradient id="d" x1={75.403} x2={75.403} y1={15.968} y2={68.189} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" stopOpacity={0} />
						<stop offset={1} stopColor="#fff" />
					</linearGradient>
				</defs>
			</svg>
		);
	}
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={170} height={175} fill="none" {...props}>
			<path
				fill="url(#a)"
				d="M151.211 84.195c15.386-13.4 17.546-.648 18.586-.443 0 0 .457 3.082 0 14.317l-149.981 86.77v-61.06C36.857 93.17 48.144 71.789 59.258 81.65c19.232 17.083 28.596 28.555 51.47 4.852 13.542-14.033 22.007 13.779 40.483-2.307Z"
				opacity={0.3}
			/>
			<path
				fill="url(#b)"
				d="M131.395 94.972c15.386-13.4 17.546-.648 18.586-.443 0 0 .457 3.082 0 14.317L0 195.616v-61.06c24.277-35.334 40.278-28.76 54.15-23.656 13.589 4.994 18.57-5.436 35.77-20.306 14.156-12.246 22.999 20.449 41.475 4.378Z"
				opacity={0.3}
			/>
			<path
				fill="#AB47BC"
				d="M49.169 80.876c0-1.264.74-2.702 1.64-3.224.898-.521 1.639.064 1.639 1.328s-.741 2.702-1.64 3.224c-.914.521-1.64-.064-1.64-1.328Z"
			/>
			<path
				fill="#CE93D8"
				d="M92.647 89.378c0-1.217.756-2.64 1.702-3.177.946-.537 1.703 0 1.703 1.217s-.757 2.64-1.703 3.176c-.93.538-1.703 0-1.703-1.216Z"
			/>
			<path fill="#fff" d="m59.652 0-29.1 16.845v57.33l29.1-16.828V0Z" opacity={0.07} />
			<path
				fill="url(#c)"
				d="m65.327 4.93-29.1 16.846v57.33l12.58-7.269 1.97 1.612 1.97-3.903 12.58-7.27V4.93Z"
				opacity={0.3}
			/>
			<path
				fill="#fff"
				d="M40.041 39.648c0 1.833.268 3.508.773 4.914.504 1.407 1.245 2.513 2.175 3.272.93.758 2.05 1.137 3.263 1.137 1.214 0 2.538-.395 3.847-1.17V33.818l-10.058 5.83Z"
				opacity={0.1}
			/>
			<path
				fill="#fff"
				d="M50.115 47.802c1.765-1.027 3.5-2.67 5.029-4.788 1.529-2.118 2.806-4.614 3.688-7.253.883-2.64 1.356-5.326 1.356-7.775 0-2.45-.473-4.599-1.356-6.21l-8.717 12.041v13.985Z"
				opacity={0.3}
			/>
			<path
				fill="#AB47BC"
				d="M40.041 39.648c0-3.082.725-6.495 2.08-9.718 1.357-3.224 3.248-6.069 5.377-8.107 2.143-2.038 4.413-3.145 6.463-3.145s3.752 1.09 4.871 3.113l-8.718 12.042-10.073 5.815Z"
			/>
			<path
				fill="#fff"
				d="M61.528 48.418 43.794 58.674v2.149l17.734-10.256v-2.149ZM61.528 52.622 40.025 65.058v2.15L61.544 54.77l-.016-2.15ZM61.544 56.81l-13.936 8.058v2.15l13.936-8.06V56.81Z"
				opacity={0.15}
			/>
			<path fill="#fff" d="m103.303 8.565-29.1 16.845v57.33l29.1-16.829V8.565Z" opacity={0.07} />
			<path
				fill="url(#d)"
				d="m108.915 13.543-29.1 16.829v57.33l12.564-7.253 1.97 1.612 1.986-3.903 12.58-7.285v-57.33Z"
				opacity={0.3}
			/>
			<path
				fill="#fff"
				d="M83.614 48.244c0 2.45.473 4.599 1.355 6.21.883 1.612 2.16 2.64 3.69 2.987l5.028-15.028-10.073 5.831Z"
				opacity={0.1}
			/>
			<path
				fill="#fff"
				d="M88.643 57.441c1.923.427 4.146-.253 6.353-1.912 2.19-1.675 4.24-4.25 5.801-7.332l-7.126-5.768-5.028 15.012Z"
				opacity={0.3}
			/>
			<path
				fill="#CE93D8"
				d="M83.614 48.244c0-2.307.41-4.804 1.182-7.285a27.494 27.494 0 0 1 3.28-6.937c1.37-2.07 2.963-3.776 4.602-4.962 1.656-1.185 3.31-1.801 4.84-1.801 1.529 0 2.885.6 3.925 1.77 1.056 1.17 1.766 2.86 2.097 4.93.331 2.07.236 4.44-.237 6.921a27.39 27.39 0 0 1-2.522 7.285l-7.125-5.767-10.042 5.846Z"
			/>
			<path
				fill="#fff"
				d="M105.116 57.015 84.986 68.66v2.149l20.13-11.646v-2.15ZM105.116 61.218 84.213 73.323v2.149l20.903-12.105v-2.149ZM105.116 65.421l-13.935 8.06v2.149l13.935-8.06v-2.149Z"
				opacity={0.15}
			/>
			<defs>
				<linearGradient id="a" x1={67.507} x2={86.691} y1={47.348} y2={108.153} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient id="b" x1={59.225} x2={70.123} y1={73.538} y2={122.748} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient id="c" x1={50.777} x2={50.777} y1={12.956} y2={90.164} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" stopOpacity={0} />
					<stop offset={1} stopColor="#fff" />
				</linearGradient>
				<linearGradient id="d" x1={94.365} x2={94.365} y1={22.356} y2={95.465} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" stopOpacity={0} />
					<stop offset={1} stopColor="#fff" />
				</linearGradient>
			</defs>
		</svg>
	);
};
export default SecondaryMarketData;
