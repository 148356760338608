import * as React from 'react';
import { SVGProps } from 'react';
import { useCheckMobileScreen } from '../../../../../../hooks';
const PreIPOFundAccess = (props: SVGProps<SVGSVGElement>) => {
	const isMobile = useCheckMobileScreen();

	if (isMobile) {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width={120} height={100} fill="none" {...props}>
				<path
					fill="#CE93D8"
					d="M67.906 53.03a1409.477 1409.477 0 0 0-3.046 9.214c5.595-1.427 10.716-7.629 14.06-13.022-3.35 1.37-7.008 2.644-11.014 3.807Z"
				/>
				<path
					fill="#AB47BC"
					d="M78.021 31.22c-3.644-.88-9.028-.303-14.409 5.336 1 1.311 2.413 3.175 3.717 4.847 3.877-3.927 7.429-7.294 10.692-10.183Z"
				/>
				<path
					fill="url(#a)"
					d="M58.485 50.73c40.64-44.188 51.513-36.358 55.722-33.463-3.691 7.98-13.174 28.915-55.494 38.104l-.228-4.64Z"
					opacity={0.2}
				/>
				<path
					fill="url(#b)"
					d="M63.704 52.684C104.327 8.492 115.2 16.32 119.425 19.22c-3.69 7.98-13.174 28.915-55.494 38.104l-.227-4.64Z"
					opacity={0.1}
				/>
				<path
					fill="url(#c)"
					d="M58.713 55.371c42.32-9.19 51.803-30.124 55.494-38.104L58.601 53.064l.112 2.307Z"
					opacity={0.15}
				/>
				<path
					fill="#fff"
					d="M99.463 32.144c-2.973 1.914-5.498 1.058-5.643-1.913-.146-2.971 2.153-6.933 5.126-8.847 2.973-1.913 5.497-1.057 5.643 1.914.146 2.97-2.154 6.933-5.126 8.846Z"
					opacity={0.1}
				/>
				<path
					fill="url(#d)"
					d="M24.164 75.19c-3.296 5.128-5.275 10.883-5.04 15.738.422 8.777 7.908 11.299 16.72 5.641 8.805-5.668 15.606-17.388 15.183-26.165-.137-2.89-1.041-5.095-2.504-6.561.884-1.84 2.458-3.465 3.838-4.894 1.588-1.65 2.944-3.048 2.749-4.236-.22-1.362-1.697-1.083-3.752-.708-1.208.223-2.638.486-4.114.473.302-1.23.443-2.415.383-3.53-.227-4.685-3.752-6.444-8.235-4.507.052-.69.073-1.36.046-2.014-.369-7.513-6.785-9.658-14.355-4.785-7.56 4.867-13.395 14.9-13.033 22.402.355 7.288 6.423 9.526 13.69 5.198-1.21 2.194-1.908 4.527-1.805 6.548.026.494.11.965.229 1.4Z"
					opacity={0.1}
				/>
				<defs>
					<linearGradient id="a" x1={122.019} x2={46.048} y1={33.274} y2={36.945} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<linearGradient id="b" x1={127.236} x2={51.265} y1={35.226} y2={38.898} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<linearGradient id="c" x1={106.102} x2={45.683} y1={35.06} y2={39.032} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" stopOpacity={0} />
						<stop offset={1} stopColor="#fff" />
					</linearGradient>
					<linearGradient id="d" x1={50.877} x2={18.063} y1={67.317} y2={68.903} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
				</defs>
			</svg>
		);
	}

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={175} height={140} fill="none" {...props}>
			<path
				fill="#CE93D8"
				d="M78.581 74.24c-1.501 4.482-3.13 9.433-4.263 12.9 7.832-1.997 15.002-10.679 19.684-18.23-4.691 1.918-9.813 3.702-15.42 5.33Z"
			/>
			<path
				fill="#AB47BC"
				d="M92.743 43.71c-5.102-1.232-12.64-.425-20.172 7.469 1.4 1.836 3.377 4.446 5.202 6.785 5.428-5.496 10.401-10.21 14.97-14.254Z"
			/>
			<path
				fill="url(#a)"
				d="M65.393 71.022C122.29 9.16 137.51 20.12 143.403 24.173c-5.167 11.173-18.444 40.481-77.691 53.346l-.32-6.497Z"
				opacity={0.2}
			/>
			<path
				fill="url(#b)"
				d="M72.698 73.757c56.873-61.869 72.094-50.907 78.011-46.849-5.168 11.173-18.445 40.48-77.692 53.346l-.319-6.497Z"
				opacity={0.1}
			/>
			<path
				fill="url(#c)"
				d="M65.712 77.52c59.247-12.866 72.524-42.174 77.691-53.347L65.554 74.29l.158 3.23Z"
				opacity={0.15}
			/>
			<path
				fill="#fff"
				d="M122.761 45.002c-4.162 2.68-7.696 1.481-7.9-2.678-.205-4.16 3.015-9.706 7.177-12.385 4.161-2.68 7.696-1.481 7.9 2.678.204 4.16-3.016 9.706-7.177 12.385Z"
				opacity={0.1}
			/>
			<path
				fill="url(#d)"
				d="M17.342 105.266c-4.613 7.18-7.384 15.236-7.056 22.034.592 12.287 11.072 15.818 23.409 7.897 12.326-7.935 21.848-24.343 21.257-36.631-.192-4.045-1.459-7.133-3.507-9.186 1.239-2.575 3.441-4.85 5.374-6.85 2.222-2.31 4.12-4.268 3.848-5.931-.307-1.907-2.375-1.516-5.253-.992-1.691.313-3.692.682-5.759.663.422-1.722.62-3.382.535-4.942-.317-6.559-5.252-9.022-11.528-6.31.072-.966.102-1.904.064-2.82-.515-10.518-9.498-13.521-20.097-6.698C8.044 62.314-.124 76.358.383 86.862c.497 10.204 8.993 13.336 19.166 7.278-1.693 3.071-2.671 6.337-2.527 9.166.037.692.154 1.352.32 1.96Z"
				opacity={0.1}
			/>
			<defs>
				<linearGradient id="a" x1={154.34} x2={47.98} y1={46.583} y2={51.723} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient id="b" x1={161.644} x2={55.283} y1={49.317} y2={54.457} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient id="c" x1={132.056} x2={47.469} y1={49.084} y2={54.645} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" stopOpacity={0} />
					<stop offset={1} stopColor="#fff" />
				</linearGradient>
				<linearGradient id="d" x1={54.741} x2={8.802} y1={94.244} y2={96.464} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
			</defs>
		</svg>
	);
};
export default PreIPOFundAccess;
