import * as React from 'react';
import { SVGProps } from 'react';
const ServicesCircle = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={308} height={308} fill="none" {...props}>
		<circle cx={153.508} cy={153.508} r={142.123} stroke="#CE93D8" strokeOpacity={0.5} />
		<g filter="url(#a)">
			<circle cx={296} cy={153} r={8} fill="#CE93D8" />
		</g>
		<g filter="url(#b)">
			<circle cx={153} cy={12} r={8} fill="#CE93D8" />
		</g>
		<g filter="url(#c)">
			<circle cx={12} cy={153} r={8} fill="#CE93D8" />
		</g>
		<g filter="url(#d)">
			<circle cx={153} cy={296} r={8} fill="#CE93D8" />
		</g>
		<defs>
			<filter
				id="a"
				width={24}
				height={24}
				x={284}
				y={141}
				colorInterpolationFilters="sRGB"
				filterUnits="userSpaceOnUse"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset />
				<feGaussianBlur stdDeviation={2} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix values="0 0 0 0 0.670588 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0" />
				<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1127_23840" />
				<feBlend in="SourceGraphic" in2="effect1_dropShadow_1127_23840" result="shape" />
			</filter>
			<filter id="b" width={24} height={24} x={141} y={0} colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset />
				<feGaussianBlur stdDeviation={2} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix values="0 0 0 0 0.670588 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0" />
				<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1127_23840" />
				<feBlend in="SourceGraphic" in2="effect1_dropShadow_1127_23840" result="shape" />
			</filter>
			<filter id="c" width={24} height={24} x={0} y={141} colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset />
				<feGaussianBlur stdDeviation={2} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix values="0 0 0 0 0.670588 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0" />
				<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1127_23840" />
				<feBlend in="SourceGraphic" in2="effect1_dropShadow_1127_23840" result="shape" />
			</filter>
			<filter
				id="d"
				width={24}
				height={24}
				x={141}
				y={284}
				colorInterpolationFilters="sRGB"
				filterUnits="userSpaceOnUse"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset />
				<feGaussianBlur stdDeviation={2} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix values="0 0 0 0 0.670588 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0" />
				<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1127_23840" />
				<feBlend in="SourceGraphic" in2="effect1_dropShadow_1127_23840" result="shape" />
			</filter>
		</defs>
	</svg>
);
export default ServicesCircle;
