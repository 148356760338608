import styled from 'styled-components';
import { useTheme } from '@mui/material';
import { useCheckMobileScreen } from '../../../../hooks';

export const Screenshot = styled('img').attrs({
	src: 'img/hero.jpeg',
	alt: 'trades in platform',
})(() => {
	const defaultTheme = useTheme();
	const isMobile = useCheckMobileScreen();

	return {
		width: '100%',
		minHeight: isMobile ? 195 : 580,
		borderRadius: isMobile ? 8 : 16,
		border: `1px solid ${defaultTheme.palette.divider}`,
	};
});
