export const addresses = [
	{
		title: 'New York',
		descriptions: ['420 Lexington Avenue', 'New York, New York 10170', '+1 212.739.9220'],
	},
	{
		title: 'Houston',
		descriptions: ['800 Town and Country Blvd. Suite 300', 'Houston, Texas 77024'],
	},
];
