import { FormControl, InputLabel, Select as MUISelect } from '@mui/material';
import { SelectProps } from '@mui/material/Select/Select';
import React from 'react';

const SelectInput: React.FC<SelectProps> = ({ label, fullWidth, required, size, ...props }) => {
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: 500,
			},
		},
	};

	return (
		<FormControl fullWidth={fullWidth} required={required} size={size}>
			{label && <InputLabel required={required}>{label}</InputLabel>}
			<MUISelect label={label} required={required} MenuProps={MenuProps} variant={'outlined'} {...props} />
		</FormControl>
	);
};

export default SelectInput;
