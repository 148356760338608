import styled from 'styled-components';
import { Stack } from '@mui/material';

export const MobileCompaniesContainer = styled(Stack).attrs({
	direction: 'row',
	spacing: 2,
	overflow: 'hidden',
	width: '100%',
})(() => {
	return {
		position: 'relative' as const,

		'&:before': {
			content: "''" as const,
			position: 'absolute' as const,
			top: 0,
			right: -12,
			width: 100,
			height: '100%',
			zIndex: 1,
			background: 'linear-gradient(270deg, rgba(30, 30, 30, 1) 0%, rgba(18, 18, 18, 0.00) 100%)',
		},
	};
});
