import * as React from 'react';
import { Tooltip } from '@mui/material';

interface SnakeCurveProps {
	snakeSteps: Array<string>;
}

const SnakeCurve: React.FC<SnakeCurveProps> = (props) => {
	const { snakeSteps } = props;
	const first = snakeSteps[0];
	const second = snakeSteps[1];
	const third = snakeSteps[2];
	const fourth = snakeSteps[3];
	const fifth = snakeSteps[4];
	const sixth = snakeSteps[5];
	const seventh = snakeSteps[6];
	const eighth = snakeSteps[7];

	return (
		<svg width="1154" height="102" viewBox="0 0 1154 102" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1 51.25C39.4 6.63462 77.8 6.63462 116.2 51.25C157.579 102.788 195.979 102.788 231.4 51.25C269.8 -0.28846 308.2 -0.28846 346.6 51.25C386.29 106.25 424.69 106.25 461.8 51.25C500.2 -3.75 538.6 -3.75 577 51.25C609.627 89.7115 648.027 89.7115 692.2 51.25C730.6 12.7885 769 12.7885 807.4 51.25C852.328 102.788 890.728 102.788 922.6 51.25C961 -0.28846 999.4 -0.28846 1037.8 51.25C1081.21 116.25 1119.61 116.25 1153 51.25"
				stroke="url(#paint0_linear_1133_25588)"
				strokeOpacity="0.5"
				strokeWidth="2"
			/>
			{first && (
				<g filter="url(#filter0_d_1133_25588)">
					<Tooltip title={first} open={true} arrow={true} placement={'bottom'}>
						<circle cx="178" cy="90" r="6" fill="#CE93D8" />
					</Tooltip>
				</g>
			)}
			{second && (
				<g filter="url(#filter1_d_1133_25588)">
					<Tooltip title={second} open={true} arrow={true} placement={'top'}>
						<circle cx="289" cy="13" r="6" fill="#CE93D8" />
					</Tooltip>
				</g>
			)}
			{third && (
				<g filter="url(#filter2_d_1133_25588)">
					<Tooltip title={third} open={true} arrow={true} placement={'bottom'}>
						<circle cx="405" cy="92" r="6" fill="#CE93D8" />
					</Tooltip>
				</g>
			)}
			{fourth && (
				<g filter="url(#filter3_d_1133_25588)">
					<Tooltip title={fourth} open={true} arrow={true} placement={'top'}>
						<circle cx="520" cy="10" r="6" fill="#CE93D8" />
					</Tooltip>
				</g>
			)}
			{fifth && (
				<g filter="url(#filter4_d_1133_25588)">
					<Tooltip title={fifth} open={true} arrow={true} placement={'bottom'}>
						<circle cx="631" cy="80" r="6" fill="#CE93D8" />
					</Tooltip>
				</g>
			)}
			{sixth && (
				<g filter="url(#filter5_d_1133_25588)">
					<Tooltip title={sixth} open={true} arrow={true} placement={'top'}>
						<circle cx="752" cy="22" r="6" fill="#CE93D8" />
					</Tooltip>
				</g>
			)}
			{seventh && (
				<g filter="url(#filter6_d_1133_25588)">
					<Tooltip title={seventh} open={true} arrow={true} placement={'bottom'}>
						<circle cx="871" cy="89" r="6" fill="#CE93D8" />
					</Tooltip>
				</g>
			)}
			{eighth && (
				<g filter="url(#filter7_d_1133_25588)">
					<Tooltip title={sixth} open={true} arrow={true} placement={'top'}>
						<circle cx="982" cy="12" r="6" fill="#CE93D8" />
					</Tooltip>
				</g>
			)}
			<defs>
				<filter
					id="filter0_d_1133_25588"
					x="168"
					y="80"
					width="20"
					height="20"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.670588 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1133_25588" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1133_25588" result="shape" />
				</filter>
				<filter
					id="filter1_d_1133_25588"
					x="279"
					y="3"
					width="20"
					height="20"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.670588 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1133_25588" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1133_25588" result="shape" />
				</filter>
				<filter
					id="filter2_d_1133_25588"
					x="395"
					y="82"
					width="20"
					height="20"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.670588 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1133_25588" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1133_25588" result="shape" />
				</filter>
				<filter
					id="filter3_d_1133_25588"
					x="510"
					y="0"
					width="20"
					height="20"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.670588 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1133_25588" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1133_25588" result="shape" />
				</filter>
				<filter
					id="filter4_d_1133_25588"
					x="621"
					y="70"
					width="20"
					height="20"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.670588 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1133_25588" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1133_25588" result="shape" />
				</filter>
				<filter
					id="filter5_d_1133_25588"
					x="742"
					y="12"
					width="20"
					height="20"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.670588 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1133_25588" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1133_25588" result="shape" />
				</filter>
				<filter
					id="filter6_d_1133_25588"
					x="861"
					y="79"
					width="20"
					height="20"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.670588 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1133_25588" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1133_25588" result="shape" />
				</filter>
				<filter
					id="filter7_d_1133_25588"
					x="972"
					y="2"
					width="20"
					height="20"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters={'sRGB'}
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.670588 0 0 0 0 0.278431 0 0 0 0 0.737255 0 0 0 1 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1133_25588" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1133_25588" result="shape" />
				</filter>
				<linearGradient
					id="paint0_linear_1133_25588"
					x1="1"
					y1="48"
					x2="1153"
					y2="55.0001"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" stopOpacity="0" />
					<stop offset="0.064255" stopColor="#CE93D8" stopOpacity="0.5" />
					<stop offset="0.936343" stopColor="#CE93D8" stopOpacity="0.5" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);
};
export default SnakeCurve;
