import React from 'react';
import { Link } from '@mui/material';

export interface Document {
	title: string;
	blocks: Array<DocumentBlock>;
}

export interface DocumentBlock {
	variant: 'h1' | 'h2';
	title: string;
	paragraphs: Array<React.ReactNode>;
}

export const privacyPolicy: Document = {
	title: 'Privacy',
	blocks: [
		{
			variant: 'h1',
			title: 'Summary',
			paragraphs: [
				'SAXON WEBER PRIVACY POLICY',
				'At Saxon Weber, our most important asset is our relationship with you. We are required by law to ask for certain information in order to perform our services for you, but we are committed to protecting your personal and financial information. This Privacy Policy describes the treatment of information provided on the sites where this Privacy Policy is posted. It also describes the treatment of information provided by you on applications that we make available on third-party sites or platforms if disclosed to you in connection with the use of such applications. This Privacy Policy also describes the choices available to you regarding our use of your information and how you can access and update this information.',
			],
		},
		{
			variant: 'h1',
			title: '1. Our Policy',
			paragraphs: [
				'Welcome to the web site www.saxonweber.com (the "Site") owned and operated by Wm Saxon Weber LLC. (Henceforward "Saxon Weber," "we," "us," and/or "our"). This Site has been created to provide information about our company and its financial matchmaking services (the "Services") to all users of the Site or Service, including entrepreneurs ("Issuers") and Accredited Investors, as defined by SEC Regulation D Rule 501 ("Investors"), who in any way use the Site or any Service provided by Saxon Weber (in each case, a "User"). This Privacy Policy sets forth Saxon Weber\'s policy with respect to information, including personally identifiable information ("Personal Data"), and other information that is collected from Site visitors and users of the Services. If you have questions or complaints regarding our privacy policy or practices, please contact us at info@saxonweber.com.',
				'IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT USE ANY SAXON WEBER SITE OR SERVICE.',
				'If we change our Privacy Policy, we will post those changes to this Privacy Policy, the Saxon Weber homepage, or other locations so that you are aware of the information we collect, how we use it, and under what circumstances, if any, we disclose it. We reserve the right to modify this Privacy Policy at any time, so please review it frequently. If we make material or significant changes to this policy, we will notify you here, by email, or by means of a notice on our homepage prior to the changes taking effect. Your continued use of our products and services will signify your acceptance of the changes to our Privacy Policy.',
			],
		},
		{
			variant: 'h1',
			title: '2. Types of Information We Collect',
			paragraphs: [
				'When you interact with us through the Site or the Services, we may collect Personal Data and other non-personal information from you, as further described below.',
				'Personal Data That You Provide Through the Site: Personal Data is information that identifies you and that can be used alone to contact you online or offline. We collect Personal Data from you when you provide such information to us such as when you register for access to the Services (for example, contact information such as your name and email) when you wish to use certain Services (for example financial information such as bank account numbers, your date of birth and the last four digits of you social security number), when you contact us with inquiries or respond to our marketing surveys and email campaigns, when you link to your profile on a third-party site or platform with your Account, and otherwise when you use our services where Personal Data is required for use and/or participation. When anonymous information is directly associated with Personal Data, this anonymous information is also treated as Personal Data.',
				'By voluntarily providing us with Personal Data, you are consenting to our use of it in accordance with this Privacy Policy and you agree that you will abide by our Terms of Use. If you provide Personal Data, you acknowledge and agree that such Personal Data may be transferred from your current location to the offices and servers of Saxon Weber and the authorized third parties referred to herein located in the United States of America.',
				'Non-Personal, Anonymous, or Aggregated Data: Non-personal information, alone, cannot be used to identify or contact you. When you interact with Saxon Weber through the Site or Services, we receive and store certain non-personal information about you for purposes that include improving our products and services, troubleshooting bugs, providing services to you, facilitating the provision of updates, dynamically served content and product support, and communicating with you. Such information, which may be collected passively using various technologies or through other means, may include demographic information including gender, age, zip code, information about your computer, hardware, software, media, mobile device, including unique device IDs or other device identifiers, incident data, Internet Protocol (IP) address, network Media Access Control (MAC) address and connection. We may also receive information from third parties in connection with marketing and demographic studies and/or other data that we use to supplement personal information provided directly by you.',
			],
		},
		{
			variant: 'h1',
			title: '3. How We Collect Information',
			paragraphs: [
				'We collect information you provide to us when you request products, services or information from us, register with us, participate in forums or other activities in our sites and applications, respond to surveys or otherwise interact with us.',
				'In order to access additional functionality of the Site, you may be required to create an account for your use of the Service (an “Account”). You may create an Account directly via the Site or by logging into your Account with certain third party social networking sites (including LinkedIn) via our Service. If you decide to create an Account by logging into a social networking site via our Service, we will obtain the Personal Data you have provided to the social networking site (such as your name, profile picture, email address, and other information you make publicly available via the applicable social networking site) from the account you have with such social networking site and use that information to create your Account. The information we obtain may depend on the privacy settings you have with the social networking site. We also collect other information that you provide as part of account registration and your use of the Service.',
			],
		},
		{
			variant: 'h2',
			title: 'Internet Log Files',
			paragraphs: [
				"As is true of most web sites, we gather certain information automatically and store it in log files. This information includes internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and click stream data. Saxon Weber may store such information itself or such information may be included in databases owned and maintained by Saxon Weber affiliates, agents, or service providers. This Site may use such information and pool it with other information to track, for example, the total number of visitors to our Site, the number of visitors to each page of our Site, the domain names of our visitors' Internet service providers, and how our users use and interact with the Services. Also, in an ongoing effort to better understand and serve the users of the Services, Saxon Weber often conducts research on its customer demographics, interests, and behavior based on Personal Data and other information provided to Saxon Weber by Issuers and Users.",
				'This research may be compiled and analyzed on an aggregate and non-aggregate basis. Saxon Weber may share this data, including Personal Data, with its affiliates, agents and business partners. Saxon Weber may also disclose such user statistics in order to describe our Services to current and prospective business partners and to other third parties for other lawful purposes.',
			],
		},
		{
			variant: 'h2',
			title: 'Cookies',
			paragraphs: [
				'In operating this Site, we may also use a technology called "cookies." A cookie is a piece of information that the computer that hosts our Site gives to your browser when you access the Site. Our cookies help provide additional functionality to the Site and help us analyze Site usage more accurately. For instance, our Site may set a cookie on your browser that allows you to access the Site without needing to remember and then enter a password more than once during a visit to the Site. We may use cookies to collect your Personal Data. On most web browsers, you will find a "help" section on the toolbar.',
				"Please refer to this section for information on how to receive notification when you are receiving a new cookie and how to turn cookies off. We recommend that you leave cookies turned on because they allow you to take advantage of some of the Site's features.",
				'The use of cookies by our any of our affiliates or service providers is not covered by our privacy policy. We do not have access or control over these cookies. Our partners may use session ID cookies to make it easier for you to navigate our site and use our Services.',
			],
		},
		{
			variant: 'h2',
			title: 'Clear GIFs and Tracking Pixels',
			paragraphs: [
				'Clear GIFs (including web bugs, beacons or tags) are small graphic images placed on a web page, web-based document, or in an email message. Clear GIFs are invisible to the user because they are small (only 1-by-1 pixel) and usually the same color as the background of the web page, document or email message. We use clear GIFs to collect your Personal Data.',
				'Furthermore, we use clear GIFs to capture statistical usage information for our web pages, features or other elements on a web page. We correlate this information to a user to personalize user experience and for statistical analysis of user experiences on our web pages.',
				'We and third parties may also use tracking pixels, which allow us to advertise more efficiently by, for example, excluding our current users from certain promotional messages or identifying the source of a new installation.',
			],
		},
		{
			variant: 'h2',
			title: 'Analytic Metric Tools and Other Technology',
			paragraphs: [
				'Saxon Weber uses third party analytics technologies to collect information when you use our online services. These tools and technologies use server log files, web beacons, cookies, tracking pixels and other technologies to collect and analyze certain types of information, including cookies, IP addresses (including for purposes of determining your approximate geographic location), mobile or other hardware device ID or other device identifiers, browser types, browser language, information passed from your browser (if any), referring and exit pages, and URLs, platform type, click information, information about your media, peripheral hardware, software and/or applications installed on your machine and/or device, domain names and types, landing pages, pages viewed and the order of those pages, advertising conversion rates, the date and amount of time spent on particular pages, other internet and website usage information, and the date and time of activity on our websites, as well as unique hardware identifies such as MAC Address, mobile unique device ID (if applicable) and other similar information.',
				'The third party analytics companies who collect information on our sites and in the context of our products and/or services may combine the information collected with other information they have independently collected from other websites and/or other online or mobile products and services relating to your activities across their network of websites as well as online and/or mobile products and services. Many of these companies collect and use information under their own privacy policies.',
			],
		},
		{
			variant: 'h2',
			title: 'This Site Uses Google Analytics and AdWords',
			paragraphs: [
				'This Site uses Google Analytics to track viewing trends and help analyze how visitors use the Saxon Weber Site. This information helps us to improve the Saxon Weber Site and the overall user experience. Google Analytics uses cookies to collect standard internet log information and visitor behavior information. This Site also uses the Google AdWords remarketing service to advertise Saxon Weber and Saxon Weber Issuers on third party websites to previous visitors of the Saxon Weber Site. Google AdWords remarketing will display relevant ads tailored to you by using a cookie that helps track what parts of the Saxon Weber Site you have viewed. Google AdWords remarketing allows us to tailor our marketing efforts to better suit your needs and display ads that are most relevant to your interests.',
				"You can set preferences for how Google advertises to you using the Google Ad Preferences page. Or, you may opt out of a third party vendor's use of cookies by visiting the Network Advertising Initiative opt out page.",
			],
		},
		{
			variant: 'h2',
			title: 'Ad Serving Technology',
			paragraphs: [
				'Saxon Weber’s websites, online or mobile products or services may employ proprietary or third party ad serving technologies that use cookies, clear GIFs, web beacons, tracking pixels or other technologies to collect information as a result of ad serving through our products or services as well as to help track results. We or third parties operating the advertisement serving technology may use information such as age and gender as well as information logged from your hardware or device to ensure that appropriate advertising is presented within the site, online or mobile product or service and to calculate or control the number of unique and repeat views of a given ad, and/or deliver ads that may relate to your interests and measure the effectiveness of ad campaigns. We or third parties may collect data for this purpose including IP address (including for purposes of determining your approximate geographic location), device IDs, information about your software, applications and hardware, browser information (and/or information passed via your browser), hardware, machine or device make and model, advertisement(s) served, length of time an advertisement was visible, other Internet and website usage information, web pages and mobile internet sites which have been viewed by you (as well as date and time), domain type, size of the advertisement, advertisement response (if any), and angle of view. The foregoing data may be used and disclosed per this policy and the privacy policy of the company providing the ad serving technology.',
				'The advertising companies who deliver ads for us may combine the information collected or obtained from Saxon Weber with other information they have independently collected from other websites and/or other online or mobile products and services relating to your web browser’s activities across their network of websites. Many of these companies collect and use information under their own privacy policies.',
			],
		},
		{
			variant: 'h2',
			title: 'Other Information Collected From Third Parties',
			paragraphs: [
				'Saxon Weber may also receive other information from third parties, including in connection with the operation of our products and services as well as marketing and demographic studies that we use to supplement personal and anonymous information or provided directly by you. Some third party services such as Twitter, Facebook, and LinkedIn may also provide us with information from your accounts there with your permission.',
				'By connecting to Saxon Weber through a social network or other third party platform or services, via one of our products and/or services, you are authorizing Saxon Weber to collect, store, and use in accordance with this Privacy Policy any and all information that you agreed the social network or other third party platform could provide to Saxon Weber through the social network/third party platform Application Programming Interface (API) based on your settings on the third party social network platform. Your agreement takes place when you connect with the third party network, platform or service via our products and/or services, and/or when you connect with, “accept” or “allow” (or similar terms) one of our applications through a social network, or other third party platform or service.',
				'Saxon Weber may also collect or receive information about you from other Saxon Weber users who choose to upload their email and other contacts. This information will be stored by us and used primarily to help you and your friends connect.',
			],
		},
		{
			variant: 'h1',
			title: '4. Our Use of Your Personal Data and Other Information',
			paragraphs: [
				"Saxon Weber uses the Personal Data you provide in a manner that is consistent with this Privacy Policy. If you provide Personal Data for a certain reason, we may use the Personal Data in connection with the reason for which it was provided. For instance, if you contact us by email, we will use the Personal Data you provide to answer your question or resolve your problem. Also, if you provide Personal Data in order to obtain access to the Services, we will use your Personal Data to provide you with access to such services and to monitor your use of such services. Please note, however, that the information you provide to us about yourself when you create an account to use certain Services on the site, including your name, will be publicly available and searchable via search engines by default. If you wish to manage your profile, you can do so at any time by changing the public profile settings on your ‘profile' page. In addition, we may provide information regarding your site activity to third parties and affiliates. We also may obtain information about you from third party sources to verify your identity so we can properly process your requests and prevent fraud. By accessing our Site and using our Services, you hereby explicitly consent that we may use your submitted and obtained Personal Information for such purposes.",
				'Saxon Weber and its subsidiaries and affiliates (the "Related Companies") may also use your Personal Data and other personally non-identifiable information collected through the Site to help us improve the content and functionality of the Site, to enforce our Terms of Use, to better understand our users, to detect, investigate and prevent activities that may violate our policies or be illegal, to personalize control and experiences on our sites and applications, and to improve the Services. Saxon Weber and its Related Companies may use this information to communicate with you regarding the Services or to tell you about services we believe will be of interest to you. If at any time, you wish not to receive any future marketing communications or you wish to have your name deleted from our mailing lists, please contact us as indicated below. Note however that as a User of the Service we will send you administrative communications that are reasonably necessary to the Service. If Saxon Weber intends on using any Personal Data in any manner that is not consistent with this Privacy Policy, you will be informed of such anticipated use prior to or at the time at which the Personal Data is collected.',
				'If you choose to use our service to refer a friend to a Saxon Weber product or site, we may ask you for your friend’s name and email address. In such cases, we will send your friend an email on your behalf inviting him or her to visit the site or check out our products. Saxon Weber retains your friend’s name and email for a short period for the sole purpose of sending this email. We do not keep or use this information for any other purpose.',
			],
		},
		{
			variant: 'h1',
			title: '5. Our Disclosure of Your Personal Data and Other Information',
			paragraphs: [
				'We will share personal and other information with third parties only in the ways that are described in this Privacy Policy. There may also be circumstances where you may share information on your own. Other than the information provided when you create an account to access Services (as described in Section 5), you may opt-out if you don’t want Saxon Weber to share your personal information with third parties and affiliates. To opt out of further communications from a marketing partner or sponsor with your information has been shared, please contact that partner or sponsor directly.',
				'From time to time, Saxon Weber employs third party contractors to collect Personal Data on our behalf to provide email delivery, products, promotional fulfillment, shipping or other services on our sites. When requesting these services, you may be asked to supply your name, mailing address, telephone number and email address to our contractors. We ask some third party contractors, such as data analytics or market research firms, to supplement personal information that you provide to us for our own marketing and demographic studies, so that we can consistently improve our sites and related advertising to better meet our visitors’ needs and preferences. To enrich our understanding of individual customers, we tie this information to the Personal Data you provide to us.',
				'When our third party agents or service providers collect and/or have access to any information other than non-personal, anonymous and/or aggregated data, Saxon Weber does not require that they use data consistently with our stated Privacy Policy.',
				'You will be notified before your Personal Data is collected by any third party that is not our agent/service provider, so you can make an informed choice as to whether or not to share your information with that party.',
				'We may also access and disclose personal information, including personal communications, in connection with report abuse functions in our products and services, to enforce legal rights and comply with the law, or to comply with an order from a government entity or other competent authority, or when we have reason to believe that a disclosure is necessary to address potential or actual injury or interference with our rights, property, operations, users or others who may be harmed or may suffer loss or damage, or when we believe that disclosure is necessary to protect our rights, combat fraud and/or comply with a judicial proceeding, court order, or legal process served on Saxon Weber. Note that certain publicly available information you post and communicate on our and third party sites and services is public information for which you have no expectation of privacy.',
			],
		},
		{
			variant: 'h2',
			title: 'Testimonials',
			paragraphs: [
				'We display personal testimonials of satisfied customers on our site in addition to other endorsements. With your consent, we may post your testimonial along with your name and other information. If you wish to update or delete your testimonial, please contact us at "This site is operated by Saxon Weber ("Company"), which is not a registered broker-dealer. The Company does not give investment advice, endorsement, analysis or recommendations with respect to any securities. All securities posted here are being offered by, and all information included on this site is the responsibility of, the applicable issuer of such securities. The Company has not taken steps to verify the adequacy, accuracy, or completeness of the information. Neither the Company nor any of its officers, directors, agents and employees makes any warranty, express or implied, of any kind related to the adequacy, accuracy or completeness of any information on this site or the use of information on this site. By accessing this site and any pages thereof, you agree to be bound by the Terms of Use (link) and Privacy Policy(link). Securities offered through North Capital Private Securities ("NCPS"), Member FINRA/SIPC, located at 623 East Ft. Union Blvd, Suite 101, Salt Lake City, UT 84047. NCPS does not make investment recommendations and no communication, through this website or in any other medium should be construed as a recommendation for any security offered on or off this investment platform. You can review the BrokerCheck for NCPS here. Investments in private offerings are speculative and involve a high degree of risk and those investors who cannot afford to lose their entire investment should not invest in such offerings. Investments in private offerings are illiquid and those investors who cannot hold an investment for an indeterminate period should not invest. Past performance is not indicative of future results.',
			],
		},
		{
			variant: 'h2',
			title: 'Public Information Including User Generated Content, Forums, Blogs, And Profiles',
			paragraphs: [
				'Our Web site offers a publicly accessible blog. You should be aware that any information you provide in this area may be read, collected, and used by others who access them. To request removal of your personal information from our blog, contact us at info@saxonweber.com. In some cases, we may not be able to remove your Personal Data, in which case we will let you know if we are unable to do so for any reason.',
				'You may choose to disclose information about yourself in the course of contributing content to Saxon Weber or in our online forums, blogs, message boards, user “profiles” for public view or in similar forums on our sites and/or on third party sites. Information that you disclose in any of these forums is unencrypted, public information, may be accessed or recorded by Saxon Weber employees, and there is no expectation of privacy or confidentiality there.',
				'You should be aware that any Personal Data you submit in the course of these public activities can be read, collected, or used by other users of these forums, and could be used to send you unsolicited messages. We are not responsible for the Personal Data you choose to make public in any of these forums.',
				'Note also that in the ordinary course of Internet activity, certain information may be sent from your browser to third parties such as advertising networks and analytics companies. Saxon Weber has no control over the information sent from your browser to those networks or other third parties and recommends that you ensure that your browser settings prevent the disclosure of information you would not like to share.',
				'If you post a video, image, or photo on one of our sites for public view should you be aware that these may be viewed, collected, copied and/or used by other users without your consent. We are not responsible for the videos, images, or photos that you choose to submit to the Saxon Weber site.',
				'There are also certain circumstances in which we may share your Personal Data and other information with certain third parties without further notice to you, as set forth below:',
			],
		},
		{
			variant: 'h2',
			title: 'Business Transfers',
			paragraphs: [
				'As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, Personal Data may be part of the transferred assets and you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.',
			],
		},
		{
			variant: 'h2',
			title: 'Related Companies',
			paragraphs: [
				'As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, Personal Data may be part of the transferred assets and you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.',
			],
		},
		{
			variant: 'h2',
			title: 'Agents, Consultants and Related Third Parties',
			paragraphs: [
				'Saxon Weber, like many businesses, sometimes hires other companies to perform certain business-related functions. Examples of such functions include mailing information, maintaining databases, ID verification, processing applications, shipping and fulfillment, and processing payments and completing transactions. When we employ another company to perform a function of this nature, we only provide them with the information that they need to perform their specific function. These companies are authorized to use your personal information only as necessary to provide these services to us.',
			],
		},
		{
			variant: 'h2',
			title: 'Legal Requirements',
			paragraphs: [
				'Saxon Weber may disclose your Personal Data if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or property of Saxon Weber, (iii) act in urgent circumstances to protect the personal safety of users of the Site or the public against fraud or other harm, or (iv) protect against legal liability.',
			],
		},
		{
			variant: 'h1',
			title: '6. Your Controls and Choices',
			paragraphs: [
				'If your Personal Data changes, or if you no longer desire our service, you may correct, update, amend, delete or request deletion by making the change in your registered profile page, by emailing us at info@saxonweber.com or by contacting us at the contact information listed at the bottom of this Privacy Policy. We will respond to your request as soon as commercially reasonable.',
				'When you sign up for our Services or newsletter(s), we will send periodic emails to you regarding the Services or to tell you about services we believe will be of interest to you. Out of respect for your privacy, you may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails or you can contact us at info@saxonweber.com.',
			],
		},
		{
			variant: 'h1',
			title: '7. Data Security, Integrity and Retention',
			paragraphs: [
				'The security, integrity and confidentiality of your information are important to us. We use commercially reasonable efforts to follow generally accepted standards to protect the personal information submitted to us, both during transmission and once we receive it. When you enter sensitive information (such as bank account information) on our website, we encrypt the transmission of that information using secure socket layer technology (SSL). We also use third party ID verification to ensure our users’ true identities are validated. From time to time, we review our security procedures to consider appropriate new technology and methods. Please be aware, however, that no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee that your information will not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. We cannot assume responsibility or liability for unauthorized access to our servers and systems. We will retain your information for as long as your Account is active or as needed to provide you services unless a longer retention period is required or permitted by law. If you wish to cancel your Account or request that we no longer use your information please contact us at info@saxonweber.com If have any questions about security on our Web site, you can also contact us at info@saxonweber.com as well.',
			],
		},
		{
			variant: 'h1',
			title: '8. Exclusions',
			paragraphs: [
				'This Privacy Policy does not apply to any Personal Data collected by Saxon Weber other than Personal Data collected through the Site or Services. This Privacy Policy shall not apply to any unsolicited information you provide to Saxon Weber through this Site or through any other means. This includes, but is not limited to, information posted to any public areas of the Site, such as bulletin boards or forums (collectively, "Public Areas"), any ideas for new products or modifications to existing products, and other unsolicited submissions (collectively, "Unsolicited Information"). All Unsolicited Information shall be deemed to be non-confidential and Saxon Weber shall be free to reproduce, use, disclose, distribute and exploit such Unsolicited Information without limitation or attribution',
			],
		},
		{
			variant: 'h1',
			title: '9. Children’s Privacy',
			paragraphs: [
				"Saxon Weber does not knowingly collect Personal Data from children under the age of eighteen. If you are under the age of eighteen, please do not submit any Personal Data through the Site. We encourage parents and legal guardians to monitor their children's Internet usage and to help enforce our Privacy Policy by instructing their children never to provide Personal Data on this Site without their permission. If you have reason to believe that a child under the age of thirteen has provided Personal Data to Saxon Weber through this Site, please contact us, and we will endeavor to delete that information from our databases.",
			],
		},
		{
			variant: 'h1',
			title: '10. Links to Other Web Sites',
			paragraphs: [
				'This Privacy Policy applies only to the Site. This Site may contain links to other web sites not operated or controlled by Saxon Weber (the "Third Party Sites"). The policies and procedures we described here do not apply to the Third Party Sites. The fact that we link to a Website is not an endorsement, authorization or representation of our affiliation with that third party. If you click on a link to a third party site, including on an advertisement, you will leave the Saxon Weber site you are visiting and go to the site you selected. Because we cannot control the activities of third parties, we cannot accept responsibility for any use of your Personal Data by such third parties, and we cannot guarantee that they will adhere to the same privacy and security practices as Saxon Weber. We encourage you to review the privacy policies of any other service provider from whom you request services. If you visit a third party Website that is linked to a Saxon Weber site, you should consult that site’s privacy policy before providing any Personal Data.',
				'Members may register for other services from our site(s). Certain products and/or services available on our site are provided to you in partnership with third party(s) and may require you to disclose Personal Data in order to register for and access such products and/or services. Such products and/or services shall identify the third party partners at the point of registration. If you elect to register for such products and/or services your Personal Data will be transferred to such third party(s) and will be subject to the privacy policy and practices of such third party(s). We are not responsible for the privacy practices and policies of such third party(s) and, therefore, you should review the privacy practices and policies of such third party(s) prior to providing your Personal Data in connection with such products and/or services.',
			],
		},
		{
			variant: 'h1',
			title: '11. Changes to this Privacy Policy',
			paragraphs: [
				'We may update this privacy policy to reflect changes to our information practices. If we make any material changes we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on this Site prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.',
			],
		},
		{
			variant: 'h1',
			title: '12. Contacting Saxon Weber',
			paragraphs: [
				"Please feel free to contact us if you have any questions about Saxon Weber's Privacy Policy or the information practices of this Site:",
				<>
					Wm Saxon Weber LLC{<br />} 174 Hudson street, 4th Floor New York, NY 10013 {<br />} info@saxonweber.com
				</>,
			],
		},
	],
};

export const termsOfUse: Document = {
	title: 'Terms of Use',
	blocks: [
		{
			variant: 'h1',
			title: 'Summary',
			paragraphs: [
				<>
					These Terms of Use (“Agreement”) should be read carefully before using the services offered by Wm. Saxon Weber
					LLC and those of its subsidiaries and affiliates, (collectively “Saxon Weber”), or accessing the websites of
					Saxon Weber or those of its subsidiaries and affiliates. This Agreement sets forth the legally binding terms
					and conditions for your use of the website at{' '}
					{
						<Link href={'https://www.saxonweber.com'} target={'_blank'}>
							www.saxonweber.com
						</Link>
					}{' '}
					(the “Site”) and the services owned and operated by Saxon Weber (collectively with the Site, the “Service”).
					By using the Site or Service in any manner, including but not limited to visiting or browsing the Site, you
					agree to be bound by this Agreement, the Privacy Policy, and all other operating rules, policies and
					procedures that may be published by Saxon Weber from time to time on the Site, each of which is incorporated
					by reference and each of which may be updated by Saxon Weber from time to time without notice to you.
				</>,
				'Your use of the Site is governed by the version of this Agreement in effect on the date of use. You agree to access the information on the Site solely for the purpose of evaluation of potential investment opportunities on the Site. Saxon Weber may may modify this Agreement at any time and without prior notice.Your continued use of and access to the Saxon Weber Site constitutes your acknowledgement of, and agreement to, the then current Terms of Use and to having reviewed the most current version of the Terms of Use. Please also note that the terms and conditions of this Agreement are in addition to any other agreements between you and Saxon Weber, including any customer or account agreements, and any other agreements that govern your use of products, services, content, tools, and information available on the Site. This Agreement does not alter in any way the terms or conditions of any other agreement you may have with Saxon Weber, or its subsidiaries or affiliates, for the Service or otherwise. If you do not agree to all the terms of this Agreement and/or Saxon Weber’s Privacy Policy, you are not eligible to participate in Saxon Weber’s Service or otherwise use this Site. This Agreement applies to all users of the Site or Service, including Companies (“Issuers”) and Accredited Investors, as defined by SEC Regulation D Rule 501 (“Investors”), who in any way use the Site or Service provided by Saxon Weber (in each case, a”User”).',
				'Saxon Weber reserves the right, in its sole discretion, without any obligation and without any notice requirement, to change, improve or correct the information, materials and descriptions on the Site and to suspend and/or deny access to the Site for any reason. The information and materials on the Site may contain typographical errors or inaccuracies. Any dated information is published as of its date only, and Saxon Weber does not undertake any obligation or responsibility to update or amend any such information. Saxon Weber may discontinue or change any product or service described in or offered on the Saxon Weber Site at any time. Saxon Weber further reserves the right, in its sole discretion, to block or otherwise discontinue your access and use of the Site at any time and for any reason. You agree that Saxon Weber and its subsidiaries and affiliates will not be liable to you or to any third party for any such modification, suspension or discontinuance.',
			],
		},
		{
			variant: 'h1',
			title: 'Investor Qualifications',
			paragraphs: [
				'The Service is available only to Accredited Investors who are at east 18 years old. You represent and warrant that if you are an individual, you are of legal age to form a binding contract, and that all registration information you submit is accurate and truthful. Saxon Weber may, in its sole discretion, refuse to offer the Service to any person or entity and change its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the Service is revoked in such jurisdictions. If you are using the Site on behalf of any entity, you represent and warrant that you are authorized to accept this Agreement on such entity’s behalf, and that such entity agrees to indemnify Saxon Weber for violations of this Agreement.',
			],
		},
		{
			variant: 'h1',
			title: 'Securities Products',
			paragraphs: [
				'Company listings on this Site are only suitable for Accredited Investors who are familiar with and willing to accept the high risk associated with private investments. Securities sold through private placements are not publicly traded and, therefore, are less liquid. Additionally, Investors may receive restricted stock that may be subject to holding period requirements. Companies seeking private placement investments tend to be in earlier stages of development and have not yet been fully tested in the public marketplace. Investing in private placements requires high risk tolerance, low liquidity concerns, and long-term commitments. Investors must be able to afford to lose their entire investment. For those reasons, these offerings may be made available only to certain institutional investors and high net worth individuals and entities. As with all alternative investments, investors must meet certain eligibility tests to qualify as purchasers. Currently, entities must have assets of at least $5 million (or all individual owners meet accredited investor tests). Individuals must have a net worth of more than $1 million excluding primary residence or gross income for each of the last two years of at least $200,000 ($300,000 with spouse) with the expectation of the same income in the current year. Associated persons of Saxon Weber who are Accredited Investors may choose, from time to time, to invest in offerings listed on the Site.',
				'The value of investments and the income from them can fall as well as rise. Past performance is not a guarantee of future performance. The contents of this Site do not constitute financial, legal, or tax advice. Investors are solely responsible for conducting any legal, accounting or due diligence review. Saxon Weber may not have verified all information contained in offering materials on the Site. You should obtain investment and tax advice from your advisers before deciding to invest. Investment products are not FDIC insured, may lose value, and there is no bank guarantee.',
				'None of the information contained in the publicly available portions of the Site constitutes a recommendation, solicitation or offer buy or sell any securities, options or other financial instruments or other assets or provide any investment advice or service. Not all Investors are granted access to all portions of the Site (including deal rooms). Investors may choose to invest in a company without accessing all portions of the Site. Please note that Investors may not see all information regarding a company prior to making investment decisions. The information contained in the Site has been prepared by without reference to any particular User’s investment requirements or financial situation. The information and services provided on the Site are not provided to, and may not be used by, any person or entity in any jurisdiction where the provision or use thereof would be contrary to applicable laws, rules or regulations of any governmental authority or where Saxon Weber is not authorized to provide such information or services. Some products and services described in the Site may not be available in all jurisdictions or to all clients.',
			],
		},
		{
			variant: 'h1',
			title: 'Modification of Agreement',
			paragraphs: [
				'Saxon Weber reserves the right, at its sole discretion, to modify or replace any of the terms of this Agreement, or change, suspend, or discontinue the Service (including without limitation, the availability of any feature, database, or content) at any time. Such modification shall become part of this Agreement and are automatically effective once posted on the Site or by sending you an email. This Agreement may not be orally amended. Saxon Weber may also impose limits on certain features and services or restrict your access to parts or all of the Service without notice or liability. It is your responsibility to check the terms of this Agreement periodically for changes. Your continued use of the Service following the posting of any changes to the Terms of Use constitutes acceptance of those changes.',
			],
		},
		{
			variant: 'h1',
			title: 'Proprietary Rights',
			paragraphs: [
				'The Saxon Weber logo is a trademark and service mark of Saxon Weber. Saxon Weber or its licensors own and retain all proprietary rights in the Site, the Service and all material and information posted thereon (“Content”). The Site contains the copyrighted material, trademarks and other proprietary information of Saxon Weber and its licensors. You may not alter, modify, publish, distribute, publicly display or sell any Content or other such proprietary information, or otherwise copy or transmit any Content or other such proprietary information without the express written permission of Saxon Weber.',
				'In addition, to the extent you receive information from Saxon Weber, Issuers or other Investors with respect to any Investment activity on the Site, you may not further disclose or otherwise provide such information to another party in any way that allows a personal identification of such Issuer or Investor. You agree that Saxon Weber may, at its sole discretion and to the extent permitted by law, access, read, preserve and disclose your account information, usage history and submitted Content in order to: (a) comply with any applicable law, regulation, legal process, or governmental request; (b) respond to claims that any Content violates the rights of third parties, including intellectual property rights; (c) enforce this Agreement and investigate potential violations thereof; (d) detect, prevent, or otherwise address fraud, security, or technical issues; (e) respond to your requests for customer service; or (f) protect the rights, property, or personal safety of Saxon Weber, its users, or the public.',
			],
		},
		{
			variant: 'h1',
			title: 'Authorized User',
			paragraphs: [
				'Our Services and certain pages of the Site are available only to Investors who have been authorized by us to access those Services and web pages on the Site. Such authorization may require completion of an accredited investor questionnaire and satisfactory background information screening and/or identification verification. Only users of Saxon Weber with a valid User ID and password are authorized to access such services and web pages.As part of Saxon Weber’s authorization process, each user shall be required to provide, among other things, a valid email address. In providing your email address and/or join by LinkedIn and agreeing to these Terms of Use, each authorized user consents to receiving email notifications and/or LinkedIn messages from Saxon Weber concerning offerings.',
				'Saxon Weber grants you a limited, non-exclusive license to use this website and any information contained therein for your personal and non-commercial use only. Content and all other intellectual property rights in or on Saxon Weber’s website, as well as any products and services offered through Saxon Weber’s website, are owned by Saxon Weber, Saxon Weber’s licensors, and/or Saxon Weber’s partners and is protected by copyright, trade dress, patent and trademark laws, international conventions, and other laws protecting intellectual property and related proprietary rights. You may not distribute, perform, display, modify, reproduce, license, lease, sell, transmit, transfer, publish, copy, create derivative works from, rent, decompile, disassemble, reverse engineer, or otherwise use in any other way for commercial or public purposes any information, software, products or services obtained from this website unless expressly authorized by Saxon Weber to do so. You agree not to remove, obscure, or alter copyright, patent, trademark, or other proprietary rights notices affixed to any content or information on the Saxon Weber website or provided to you by Saxon Weber.',
				<>
					Unauthorized use of the Site and our Service, including, but not limited to the misuse or sharing of passwords
					or misuse of any other information, is strictly prohibited. You may not use the Site in any manner that could
					damage, disable, overburden, or impair the Site or Service or interfere with any other party’s use and
					enjoyment of the Site or Service. You agree that you will not engage in any activities related to the Site
					that are contrary to applicable laws or regulations. You further agree that you are responsible for any
					unauthorized use of your password that is made before you have notified us and we have had a reasonable
					opportunity to act on that notice. Saxon Weber reserves the right at its discretion to suspend or cancel your
					password, even without receiving such notice from you, if we suspect that it is being used in an unauthorized
					or fraudulent manner.Notwithstanding the above, you are responsible for monitoring your account and should
					promptly report any unauthorized or suspicious activity in your account to us at{' '}
					{
						<Link href={'mailto:support@saxonweber.com'} target={'_blank'}>
							support@saxonweber.com
						</Link>
					}
					.
				</>,
			],
		},
		{
			variant: 'h1',
			title: 'Use of Links',
			paragraphs: [
				'The Site may permit you to link to other websites or resources on the Internet, and other websites or resources may contain links to the Site. When you access third party websites, you do so at your own risk. These other websites are not under Saxon Weber’s control, and you acknowledge that Saxon Weber is not responsible or liable for the content, functions, accuracy, legality, appropriateness or any other aspect of such websites or resources. The inclusion of any such link does not imply an affiliation, sponsorship, or endorsement by Saxon Weber or any association with its operators. You further acknowledge and agree that Saxon Weber shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any information, goods or services available on or through any such website or resource. You should also be aware that the terms and conditions and privacy policy of such sites may be different from those applicable to your use of the Saxon Weber Site and Service.',
			],
		},
		{
			variant: 'h1',
			title: 'Third Party Content',
			paragraphs: [
				'Certain portions of the Site may contain third party content and may provide links to webpages and content of third parties (collectively “Third-Party Content”). Saxon Weber does not control, endorse, investigate, or adopt any Third-Party Content, and makes no representations or warranties of any kind regarding the Third-Party Content, including with respect to its accuracy or completeness. You acknowledge and agree that Saxon Weber is not responsible or liable in any manner for any Third-Party Content or your reliance upon Third-Party Content. Users use such Third-Party Content at their own risk.',
			],
		},
		{
			variant: 'h1',
			title: 'Copyright Policy',
			paragraphs: [
				'If you believe that any material on the Site violates this Agreement or your intellectual property rights, please notify Saxon Weber as soon as possible by sending an email to info@Saxon Weber.com, with the following information in accordance with Section 512(c) of the Copyright Act: (i) a physical or electronic signature of the copyright owner or a person authorized to act on their behalf; (ii) identification of the copyrighted work claimed to have been infringed; (iii) identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material; (iv) your contact information, including your address, telephone number, and an email address; (v) a statement by you that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and (vi) a statement that the information in the notification is accurate, and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.',
				'We reserve the right to remove Content alleged to be infringing or otherwise illegal without prior notice and at our sole discretion. In appropriate circumstances, Saxon Weber will also terminate a User’s account if the user is determined to be a repeat infringer.',
			],
		},
		{
			variant: 'h1',
			title: 'Termination',
			paragraphs: [
				'Saxon Weber may terminate your access to all or any part of the Site and Service at any time, with or without cause, with or without notice, effective immediately, which may result in the forfeiture and destruction of all information associated with your membership. If you wish to terminate your account, you may do so by following the instructions on the Site. All provisions of this Agreement shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.',
			],
		},
		{
			variant: 'h1',
			title: 'Warranty Disclaimer',
			paragraphs: [
				'Saxon Weber has no special relationship with or fiduciary duty to you. You acknowledge that Saxon Weber has no control over, and no duty to take any action regarding: which users gains access to the Site; what Content you access via the Site; what effects the Content may have on you; how you may interpret or use the Content; or what actions you may take as a result of having been exposed to the Content. You release Saxon Weber from all liability for you having acquired or not acquired Content through the Site. Saxon Weber makes no representations concerning any Content contained in or accessed through the Site, and Saxon Weber will not be responsible or liable for the accuracy, copyright compliance, legality or decency of material contained in or accessed through the Site or the Service.',
				'Saxon Weber neither represents, warrants, covenants guarantees, nor promises any specific results from use of the Site.',
				'THE SITE, INCLUDING ANY CONTENT OR INFORMATION CONTAINED WITHIN IT OR ANY SERVICE OR ADVICE PROVIDED IN CONNECTION WITH THE SERVICE, IS PROVIDED “AS IS” WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THIS SITE AND SITE- RELATED SERVICES.',
				'Saxon Weber makes no representation or warranty, express or implied, with respect to any third-party data provided to Saxon Weber or its transmission, timeliness, accuracy or completeness, including but not limited to implied warranties or warranties of merchantability or fitness for a particular purpose. Saxon Weber will not be liable in any way to you or to any other person for any inaccuracy, error or delay in or omission of any third-party data or the transmission or delivery of any such third-party data and any loss or damage arising from (a) any such inaccuracy, error, delay or omission, (b) non-performance or (c) interruption in any such third-party data due either to any negligent act or omission by Saxon Weber or “force majeure” or any other cause beyond the control of Saxon Weber.',
				'THE SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE” AND IS WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY ANDFITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. YOUR USE OF THE SERVICE IS SOLELY AT YOUR OWN RISK.',
				'Electronic Communication Privacy Act Notice (18usc 2701-2711): Saxon Weber makes no guaranty of confidentiality or privacy of any communication or information transmitted on the site or any website linked to the site. Saxon Weber will not be liable for the privacy of email addresses, registration and identification information, disk space, communications, confidential or trade- secret information, or any other Content stored on Saxon Weber’s equipment, transmitted over networks accessed by the Site, or otherwise connected with your use of the Service.',
			],
		},
		{
			variant: 'h1',
			title: 'Indemnification',
			paragraphs: [
				'You shall defend, indemnify, and hold harmless Saxon Weber, its affiliates and each of its, and its affiliates employees, contractors, directors, suppliers and representatives from all liabilities, claims, and expenses, including reasonable attorneys’ fees, that arise from or relate to your use or misuse of, or access to, the Site, Service, Content or otherwise from your User Submissions, violation of the Agreement, or infringement by you, or any third party using the your account, of any intellectual property or other right of any person or entity. Saxon Weber reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will assist and cooperate with Saxon Weber in asserting any available defenses.',
			],
		},
		{
			variant: 'h1',
			title: 'Limitation of Liability',
			paragraphs: [
				'IN NO EVENT SHALL SAXON WEBER, NOR ITS DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICE (I) FOR ANY LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER, SUBSTITUTE GOODS OR SERVICES (HOWEVER ARISING), (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE OF ORIGINATION), OR (III) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) ONE- HUNDRED U.S. DOLLARS ($100.00). SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.',
			],
		},
		{
			variant: 'h1',
			title: 'Governing Law; Arbitration',
			paragraphs: [
				'This Agreement (and any further rules, policies or guidelines incorporated by reference herein) shall be governed by and construed in accordance with the laws of the State of Delaware, without giving effect to any principles of conflicts of law. Unless otherwise agreed in writing by User and Saxon Weber, any dispute arising out of or relating to the Agreement, or the breach hereof, shall be finally resolved by arbitration administered by the American Arbitration Association (“AAA”) under its Commercial Arbitration Rules. If parties are unable to select an arbitrator then AAA shall select the arbitrator. Judgment on any award entered by the arbitrator may be entered in any court having jurisdiction thereof. User agrees that it may bring claims against Saxon Weber only in an individual capacity and not as a plaintiff or class member in any purported class or representative proceeding.',
				<>
					FINRA BrokerCheck Hotline – The Financial Industry Regulatory Authority, Inc. offers investors information and
					education through the FINRA BrokerCheck Hotline at{' '}
					{
						<Link href={'tel:800-289-9999'} target={'_blank'}>
							800-289-9999
						</Link>
					}{' '}
					and FINRA website at{' '}
					{
						<Link href={'https://www.finra.org'} target={'_blank'}>
							www.finra.org
						</Link>
					}
					.
				</>,
			],
		},
		{
			variant: 'h1',
			title: 'Registration Data; Account Security',
			paragraphs: [
				'In consideration of your use of the Site, you agree to (a) provide accurate, current and complete information about you as may be prompted by any registration forms on the Site (“Registration Data”); (b) maintain the security of your password and identification; (c) maintain and promptly update the registration Data, and any other information that you provide to Saxon Weber, to keep it accurate, current and complete; and (d) accept all risks of unauthorized access to the Registration Data and any other information you provide to Saxon Weber.',
			],
		},
		{
			variant: 'h1',
			title: 'Integration and Severability',
			paragraphs: [
				'This Agreement is the entire agreement between you and Saxon Weber with respect to the Service and use of the Site, and supersede all prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and Saxon Weber with respect to the Site. If any provision of the Terms of Use is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the Agreement will otherwise remain in full force and effect and enforceable. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder.',
			],
		},
	],
};

export const careers: Document = {
	title: 'Careers',
	blocks: [
		{
			variant: 'h1',
			title: 'Marketing Intern',
			paragraphs: [
				<>
					India
					<br />
					Marketing and Communications
				</>,
			],
		},
		{
			variant: 'h2',
			title: 'Your role',
			paragraphs: [
				'Are you passionate about marketing communications and branding? Are you motivated to deliver exceptional services to our clients? Do you feel comfortable approaching and working with different people?',
				'We are looking for someone with these attributes to support us with the following responsibilities:',
				<ul>
					<li>Develop brand campaigns brand design and guidelines for a consistent look & feel.</li>
					<li>
						Manage content marketing, social media planning and execute advertising campaigns on social media accounts.
					</li>
					<li>Source and generate leads for transactions on and off the platform.</li>
					<li>
						Engage in business development projects, including client/market research and business planning support.
					</li>
					<li>
						Build relationships with institutional investors, sellers and venture ecosystem networks to expand Saxon
						Weber's access.
					</li>
					<li>
						Facilitate transactions between institutional private equity investors and accredited individual investors
						on our proprietary platform.
					</li>
					<li>Assist the product development team and support project management of product roadmaps.</li>
					<li>
						Contribute to sales strategy, including client acquisition for buyers, sellers and company's liquidity
						programs.
					</li>
				</ul>,
			],
		},
		{
			variant: 'h2',
			title: 'Internship to Full Time',
			paragraphs: [
				"We're looking for interns who are passionate about start-ups, company building, and venture capital. As an intern, you'll be fully immersed in our day-to-day operations, providing a valuable learning experience for you and an opportunity for us to identify talented individuals who share our vision and values, with the potential for full-time employment.",
			],
		},
		{
			variant: 'h2',
			title: 'Your expertise',
			paragraphs: [
				<ul>
					<li>Bachelor's degree in Finance, Business Administration, Marketing or a related field. </li>
					<li>Excellent communication, writing, and copywriting skills.</li>
					<li>Strong creative and strategic thinking skills.</li>
					<li>Proficiency in MS Office (Excel, Word, PowerPoint), Canva and Shutterstock.</li>
					<li>Familiarity with SEO/SEM best practices, marketing automation tools and technologies.</li>
					<li>
						Highly organized and structured work approach that fits well into lean teams, with an independent and
						result-oriented attitude.
					</li>
					<li>Is willing to work in international shifts and is adaptable to a dynamic work environment.</li>
				</ul>,
			],
		},
		{
			variant: 'h2',
			title: 'About us',
			paragraphs: [
				'We are a premier private secondary brokerage firm and investment bank, specializing in pre-IPO shares, and serving institutional capital markets. Our key focus areas include Investment Banking, Private Placement, Secondary Brokerage, Market Coverage, and Venture Capital.',
				'And we do it for Technology Companies, Venture Capital, Private Equity, Hedge Funds, Family Offices, and executives across diverse markets.',
				'Join us to be a part of a team that is committed to delivering exceptional results in these key areas.',
			],
		},
	],
};
