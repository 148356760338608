export interface StakeholderStep {
	index: number;
	title: string;
	description: string;
}

export const shareholderSteps: Array<StakeholderStep> = [
	{
		index: 1,
		title: 'Sign-Up Today',
		description: 'Receive custom credentials through our secure platform interface.',
	},
	{
		index: 2,
		title: 'Join the ShareWeller Community',
		description: 'Select your company, log your basic portfolio, and launch your full-access account.',
	},
	{
		index: 3,
		title: 'Access the Secondary Market',
		description: 'Monitor vetted bids and comprehensive pricing data, all through a user-friendly trading dashboard.',
	},
	{
		index: 4,
		title: 'Onboarding',
		description: 'Move ahead and complete your onboarding and compliance requirements.',
	},
	{
		index: 5,
		title: 'Matching',
		description: 'Get connected with buyers and initiate your transactions through a dedicated trading window.',
	},
	{
		index: 6,
		title: 'Clearing',
		description:
			'Manage your transactions, documentation, and company processes with support from your professional Account Manager.',
	},
	{
		index: 7,
		title: 'Settlement',
		description: 'Complete your transaction and receive the proceeds of your sale in a timely and efficient process.',
	},
];

export const investorSteps: Array<StakeholderStep> = [
	{
		index: 1,
		title: 'Platform Credentials',
		description:
			'Receive custom credentials through our secure platform interface.',
	},
	{
		index: 2,
		title: 'Select your Secondary Data Environment',
		description: 'Develop watchlists, customize your data view, and launch your full-access dashboard.',
	},
	{
		index: 3,
		title: 'Monitor the Market',
		description:
			'Access comprehensive pricing data and market color across portfolio companies and new targets, all through a user-friendly trading dashboard.',
	},
	{
		index: 4,
		title: 'Trading',
		description: 'Dedicated trading support across a dynamic, global market. Leveraging real-time actionable intelligence through our proprietary network.',
	},
	{
		index: 5,
		title: 'Clearing',
		description:
			"Team support on all your transactions, documentation, and company processes from one streamlined system.",
	},
	{
		index: 6,
		title: 'Settlement',
		description: 'Complete your transaction and receive the proceeds of your sale in a timely and efficient process.',
	},
];

export const shareholderScreenshots: Array<string> = [
	'/img/shareholder/1.png',
	'/img/shareholder/2.png',
	'/img/shareholder/3.png',
];

export const investorScreenshots: Array<string> = ['/img/investor/1.png', '/img/investor/2.png', '/img/investor/3.png'];

export const shareholderSnakeSteps: Array<string> = [
	'Client Onboarding',
	'Compliance & Approvals',
	'Live Ticketing',
	'Share Transfer Notice Received',
	'Company Noticed',
	'Company Approval & Share Assignment',
	'Share Transfer Agreement Signed',
	'Funding Wire(s), Closing & Invoicing',
];

export const investorSnakeSteps: Array<string> = [
	'Client Onboarding',
	'Compliance & Trading Approvals',
	'Bids/Offers',
	'Share Transfer Notice Received',
	'Company Noticed',
	'Company Approval & Share Assignment',
	'Share Transfer Agreement Signed',
	'Funding Wire(s), Closing & Invoicing',
];
