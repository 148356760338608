import { Box, Grid, SvgIcon, Typography, useTheme } from '@mui/material';
import styled from 'styled-components';
import { useCheckMobileScreen } from '../hooks';

export const MainMobileBox = styled(Box)`
	width: 100vw;
	padding: 16px 24px 52px;
	overflow-x: hidden;
`;

export const MainMobileGrid = styled(Grid).attrs({
	container: true,
	columns: 4,
	columnSpacing: 2,
})``;

export const BlurCircle = styled('div')(() => {
	const defaultTheme = useTheme();

	return {
		position: 'absolute' as const,
		width: '100%',
		height: '100%',
		background: defaultTheme.palette.primary.dark,
		borderRadius: '50%',
		opacity: 0.4,
		filter: 'blur(63.5px)',
		zIndex: -1,
	};
});

export const CustomBigTypography = styled(Typography).attrs(() => {
	const isMobile = useCheckMobileScreen();

	return {
		variant: 'h4',
		fontWeight: 300,
		fontSize: isMobile ? 28 : undefined,
	};
})``;

export const SocialIconLink = styled(SvgIcon)(() => {
	const defaultTheme = useTheme();
	return {
		color: defaultTheme.palette.text.disabled,
		fontSize: 24,
		transition: 'all 0.3s ease',

		'&:hover': {
			cursor: 'pointer',
			color: defaultTheme.palette.primary.main,
		},
	};
});

export const headerHeight = 64;
