import React from 'react';
import { Box } from '@mui/material';
import Header from '../Header';
import ScrollToTop from '../ScrollToTop';

interface MainLayoutProps {
	children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = (props) => {
	const { children } = props;

	return (
		<Box>
			<Header />
			<Box sx={{ overflowX: 'hidden' }}>{children}</Box>
			<ScrollToTop />
		</Box>
	);
};

export default MainLayout;
