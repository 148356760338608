import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const NotFoundContainer = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;
