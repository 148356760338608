import { StakeholderStep } from '../../steps';
import { Collapse, Stack, Typography } from '@mui/material';
import React from 'react';
import { StepTitle, StepWrapper } from './style';

export type StepProps = StakeholderStep & {
	selected: boolean;
	onClick: () => void;
};

const Step: React.FC<StepProps> = (props) => {
	const { selected, index, title, description, onClick } = props;
	return (
		<StepWrapper onClick={onClick}>
			<Typography variant={'h6'} paragraph color={'primary.main'} style={{ marginBottom: 0 }}>
				{index}
			</Typography>
			<Stack spacing={'12px'}>
				<StepTitle color={selected ? 'text.primary' : 'text.secondary'} fontWeight={selected ? 500 : 400}>
					{title}
				</StepTitle>
				<Collapse in={selected}>
					<Typography variant={'body1'} color={'text.secondary'} mb={2}>
						{description}
					</Typography>
				</Collapse>
			</Stack>
		</StepWrapper>
	);
};

export default Step;
