import { Container, Paper, Stack, ThemeProvider, Typography } from '@mui/material';
import { lightTheme } from '../../styles/theme';
import FooterSection from '../../components/FooterSection';
import { BlurCircle } from '../../styles/common.styles';
import React, { useEffect, useState } from 'react';
import { careers, Document, privacyPolicy, termsOfUse } from './documents';
import { useLocation } from 'react-router-dom';
import Block from './Block';
import { useCheckMobileScreen } from '../../hooks';

const DocumentPage = () => {
	const [document, setDocument] = useState<Document>({ title: '', blocks: [] });
	const location = useLocation();
	const isMobile = useCheckMobileScreen();

	useEffect(() => {
		switch (location.pathname) {
			case '/privacy':
				setDocument(privacyPolicy);
				break;
			case '/terms':
				setDocument(termsOfUse);
				break;
			case '/careers':
				setDocument(careers);
				break;
		}
	}, [location]);

	return (
		<Stack>
			<Container>
				<Stack pt={isMobile ? '80px' : '100px'} spacing={'50px'}>
					<Typography variant={isMobile ? 'h2' : 'h3'}>{document.title}</Typography>
					<ThemeProvider theme={lightTheme}>
						<Paper sx={{ borderRadius: isMobile ? 1 : '16px', position: 'relative' }}>
							<Stack pl={isMobile ? 2 : '60px'} pr={isMobile ? 2 : '60px'} pb={isMobile ? 4 : '70px'}>
								{document.blocks.map((block) => (
									<Block {...block} key={block.title} />
								))}
							</Stack>
							<BlurCircle
								style={{
									width: isMobile ? 220 : 478,
									height: isMobile ? 220 : 478,
									top: isMobile ? -60 : -110,
									right: isMobile ? -60 : -110,
								}}
							/>
							<BlurCircle
								style={{
									width: isMobile ? 220 : 478,
									height: isMobile ? 220 : 478,
									bottom: isMobile ? -60 : -110,
									left: isMobile ? -60 : -110,
								}}
							/>
						</Paper>
					</ThemeProvider>
				</Stack>
			</Container>
			<FooterSection />
		</Stack>
	);
};

export default DocumentPage;
