import { Container, Grid, Link, Stack, Typography } from '@mui/material';
import Address from './components/Address';
import { addresses } from './components/addresses';
import { socialLinks } from '../../utils/endpoints';
import { LinkedIn, Mail, Twitter } from '@mui/icons-material';
import { SocialIconLink } from '../../styles/common.styles';
import { Link as RouterLink } from 'react-router-dom';
import { useCheckMobileScreen } from '../../hooks';

const FooterSection = () => {
	const termsLink = '/terms';
	const privacyLink = '/privacy';
	const finraLink = 'https://www.finra.org/';
	const sipcLink = 'https://www.sipc.org/';
	const brokerCheckLink = 'https://brokercheck.finra.org/firm/summary/166316';
	const formCRSLink = 'https://public.saxonweber.com/FNEX_CRS.pdf';
	const isMobile = useCheckMobileScreen();

	const disclosures = (
		<Stack spacing={'20px'}>
			<Typography variant={isMobile ? 'body2' : 'caption'} color={'text.disabled'}>
				Disclosures
			</Typography>
			<Typography variant={isMobile ? 'body2' : 'caption'} color={'text.disabled'} textAlign={'justify'}>
				The information contained herein is provided for informational and educational purposes only. None of the
				information provided represents an offer to buy or sell, or the solicitation of an offer to buy or sell, any
				security, nor does it constitute an offer to provide investment advice or service. Any information relating to
				company financing, valuation, or capitalization information should be independently verified by you in
				connection with any investment decision. The material is based in part on information from third-party sources
				that we believe to be reliable, but which have not been independently verified by us and for this reason we do
				not represent that the information is accurate or complete. There may exist other material non-public
				information that impacts the valuation of any securities. Past performance is not indicative of future results.
			</Typography>
			<Typography variant={isMobile ? 'body2' : 'caption'} color={'text.disabled'} textAlign={'justify'}>
				This site is operated by SaxonWeber / ShareWell (“Company”), which is not a registered broker-dealer. The
				Company does not give investment advice, endorsement, analysis or recommendations with respect to any
				securities. All securities posted here are being offered by, and all information included on this site is the
				responsibility of, the applicable issuer of such securities. The Company has not taken steps to verify the
				adequacy, accuracy, or completeness of the information. Neither the Company nor any of its officers, directors,
				agents and employees makes any warranty, express or implied, of any kind related to the adequacy, accuracy or
				completeness of any information on this site or the use of information on this site. By accessing this site and
				any pages thereof, you agree to be bound by the{' '}
				<Link underline={'hover'} variant={isMobile ? 'body2' : 'caption'} href={termsLink} target={'_blank'}>
					Terms of Use
				</Link>{' '}
				and{' '}
				<Link underline={'hover'} variant={isMobile ? 'body2' : 'caption'} href={privacyLink} target={'_blank'}>
					Privacy Policy
				</Link>
				.
				<br />
				<br />
				Securities are offered through FNEX Capital LLC (“FNEX”), Member{' '}
				<Link underline={'hover'} variant={isMobile ? 'body2' : 'caption'} href={finraLink} target={'_blank'}>
					FINRA
				</Link>
				/
				<Link underline={'hover'} variant={isMobile ? 'body2' : 'caption'} href={sipcLink} target={'_blank'}>
					SIPC
				</Link>
				, located at One Indiana Square, Suite 2252, Indianapolis, IN 46204. FNEX does not make investment
				recommendations and no communication, through this website or in any other medium should be construed as a
				recommendation for any security offered on or off this investment platform. You can review the BrokerCheck for
				FNEX{' '}
				<Link underline={'hover'} variant={isMobile ? 'body2' : 'caption'} href={brokerCheckLink} target={'_blank'}>
					here
				</Link>
				. Investments in private offerings are speculative and involve a high degree of risk and those investors who
				cannot afford to lose their entire investment should not invest in such offerings. Investments in private
				offerings are illiquid and those investors who cannot hold an investment for an indeterminate period should not
				invest. Past performance is not indicative of future results.
				<br />
				<br />
				Nothing set forth here shall constitute an offer to sell any securities or a solicitation of an offer to
				purchase securities in any jurisdiction. Any offer to sell or solicitation of an offer to purchase shall be made
				only to qualified investors through a private placement memorandum or associated offering document ("Offering
				Document"). The specific terms of an investment are subject to the Offering Document, which will contain
				additional information not set forth here, including a description of certain risks of investing, which will be
				material to any qualified investors decision to invest.
				<br />
				<br />
				Investment opportunities posted on this website are "private placements" of securities that are not publicly
				traded, are subject to holding period requirements, and are intended for investors who do not need a liquid
				investment. Investing in private companies may be considered highly speculative and involves a high degree of
				risk, including the risk of substantial loss of investment. Investors must be able to afford the loss of their
				entire investment.
				<br />
				<br />
				Before you work with SaxonWeber | ShareWell you should review the{' '}
				<Link underline={'hover'} variant={isMobile ? 'body2' : 'caption'} href={formCRSLink} target={'_blank'}>
					Form CRS
				</Link>
			</Typography>
		</Stack>
	);

	return (
		<Stack pt={isMobile ? '100px' : '140px'} pb={'40px'} id={'footer-section'}>
			<Stack pt={10} spacing={isMobile ? '15px' : '50px'} borderTop={'1px solid #252525'} alignItems={'center'}>
				<Container>
					<Grid container spacing={3} rowSpacing={isMobile ? '10px' : undefined}>
						<Grid item xs={isMobile ? 12 : 9}>
							{disclosures}
						</Grid>
						{!isMobile && <Grid item xs={1} />}
						<Grid item xs={isMobile ? 12 : 2}>
							<Stack
								height={'100%'}
								justifyContent={isMobile ? undefined : 'space-between'}
								spacing={isMobile ? '15px' : undefined}
							>
								<Stack spacing={3} pt={'40px'}>
									<Stack spacing={3} direction={isMobile ? 'row' : 'column'}>
										{addresses.map((address) => (
											<Address {...address} key={address.title} />
										))}
									</Stack>
									<Stack spacing={isMobile ? '12px' : 3} alignItems={isMobile ? 'center' : undefined}>
										<Stack spacing={'12px'} direction={'row'} justifyContent={isMobile ? 'center' : undefined}>
											<RouterLink to={socialLinks.linkedin} target={'_blank'}>
												<SocialIconLink>
													<LinkedIn />
												</SocialIconLink>
											</RouterLink>
											<RouterLink to={socialLinks.twitter} target={'_blank'}>
												<SocialIconLink>
													<Twitter />
												</SocialIconLink>
											</RouterLink>
											<RouterLink to={socialLinks.mailto} target={'_blank'}>
												<SocialIconLink>
													<Mail />
												</SocialIconLink>
											</RouterLink>
										</Stack>
										<Link href={socialLinks.mailto} target={'_blank'} underline={'hover'} variant={'body2'}>
											{socialLinks.mail}
										</Link>
									</Stack>
								</Stack>
								<Stack
									spacing={isMobile ? '26px' : '4px'}
									direction={isMobile ? 'row' : undefined}
									justifyContent={isMobile ? 'center' : undefined}
								>
									<Link to={'/careers'} underline={'hover'} variant={'body2'} component={RouterLink}>
										Careers
									</Link>
									<Link to={termsLink} underline={'hover'} variant={'body2'} component={RouterLink}>
										Terms of Use
									</Link>
									<Link to={privacyLink} underline={'hover'} variant={'body2'} component={RouterLink}>
										Privacy Policy
									</Link>
								</Stack>
							</Stack>
						</Grid>
					</Grid>
				</Container>
				<Typography variant={'caption'} color={'text.secondary'}>
					© 2023 Saxon Weber | ShareWell. All Rights Reserved.
				</Typography>
			</Stack>
		</Stack>
	);
};

export default FooterSection;
