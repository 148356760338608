import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';

export const useCheckMobileScreen = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const defaultTheme = useTheme();
	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	return width <= defaultTheme.breakpoints.values.md;
};
