import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Company } from '../types/company';
import { endpoints } from '../utils/endpoints';
import { apiClient } from '../utils/apiClient';

type CompaniesContextType = {
	companies: Array<Company>;
	// setCompanies: (companies: any) => void;
	companiesLoading: boolean;
	// setCompaniesLoading: (companiesLoading: any) => void;
};

const CompaniesContext = createContext({
	companies: [],
	// setCompanies: () => {},
	companiesLoading: true,
	// setCompaniesLoading: () => {},
} as CompaniesContextType);

interface CompaniesContextProviderProps {
	children: ReactNode;
}

export const CompaniesContextProvider = ({ children }: CompaniesContextProviderProps) => {
	const [companies, setCompanies] = useState<Array<Company>>([]);
	const [companiesLoading, setCompaniesLoading] = useState<boolean>(true);

	useEffect(() => {
		loadCompanies().then((res) => setCompanies(res));
	}, []);

	const loadCompanies = async () => {
		try {
			const res = await apiClient.get(endpoints.getAllCompaniesNoToken);
			return res.data ?? [];
		} catch (err) {
			return [];
		} finally {
			setCompaniesLoading(false);
		}
	};

	return (
		<CompaniesContext.Provider
			value={{
				companies,
				// setCompanies,
				companiesLoading,
				// setCompaniesLoading,
			}}
		>
			{children}
		</CompaniesContext.Provider>
	);
};

export const useCompaniesContext = () => useContext(CompaniesContext);
