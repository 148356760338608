import * as React from 'react';
import { SVGProps } from 'react';
import { useCheckMobileScreen } from '../../../../../../hooks';
const PlatformTrading = (props: SVGProps<SVGSVGElement>) => {
	const isMobile = useCheckMobileScreen();

	if (isMobile) {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width={120} height={100} fill="none" {...props}>
				<path
					fill="url(#a)"
					d="M55.706 56.18c13.763-6.57 25.146-.422 27.16 13.738.35 2.462-1.93 5.7-4.693 7.019L33.256 98.361c-2.763 1.319-5.043.258-4.71-2.53 2.014-16.078 13.397-33.095 27.16-39.651Z"
					opacity={0.5}
				/>
				<path
					fill="url(#b)"
					d="M40.362 37.777c0 9.78 6.923 14.404 15.46 10.324 8.538-4.067 15.444-15.303 15.444-25.07 0-9.78-6.923-14.404-15.46-10.323-8.538 4.067-15.444 15.302-15.444 25.069Z"
					opacity={0.5}
				/>
				<path
					fill="url(#c)"
					d="M49.05 52.1c13.762-6.57 25.145-.422 27.159 13.738.35 2.462-1.93 5.7-4.693 7.019L26.599 94.28c-2.763 1.319-5.043.258-4.71-2.53C23.903 75.672 35.286 58.656 49.05 52.1Z"
					opacity={0.07}
				/>
				<path
					fill="url(#d)"
					d="M33.705 33.696c0 9.78 6.924 14.404 15.46 10.324 8.522-4.067 15.445-15.29 15.445-25.07 0-9.78-6.923-14.404-15.46-10.323-8.538 4.067-15.445 15.302-15.445 25.069Z"
					opacity={0.07}
				/>
				<path
					fill="url(#e)"
					d="M67.995 78.282c0 9.159 5.35 13.496 11.932 9.682 6.6-3.813 11.931-14.336 11.931-23.495 0-9.16-5.35-13.496-11.931-9.683-6.6 3.813-11.95 14.337-11.932 23.496Z"
					opacity={0.2}
				/>
				<path
					fill="#CE93D8"
					d="M85.65 91.702c-6.599 3.813-11.93-.524-11.95-9.683 0-9.159 5.351-19.682 11.932-23.495 6.6-3.813 11.931.523 11.931 9.682.02 9.16-5.331 19.683-11.912 23.496Zm2.051-26.188-2.293 1.328v2.224l-.67.448a22.96 22.96 0 0 0-1.623 1.216v14.43a8.876 8.876 0 0 0 1.809-.71c.167-.076.316-.15.484-.244v2.225l2.293-1.328v-2.242c1.119-.786 1.995-1.664 2.629-2.636.82-1.271 1.23-2.673 1.23-4.243 0-.766-.13-1.402-.372-1.87-.243-.467-.709-.69-1.38-.653.82-1.16 1.23-2.337 1.23-3.533 0-.785-.111-1.383-.354-1.776-.224-.411-.54-.673-.95-.748-.41-.074-.914-.037-1.473.169-.168.056-.355.13-.541.205l-.019-2.261Z"
					opacity={0.4}
				/>
				<path
					fill="#CE93D8"
					d="m86.731 74.375-1.174.672v-3.233c.186-.131.391-.28.634-.411.242-.15.466-.28.67-.412.635-.373 1.12-.523 1.455-.467.335.056.485.374.485.972 0 .58-.168 1.103-.485 1.57-.317.449-.839.879-1.585 1.309ZM85.557 81.423v-3.85l1.51-.88c.69-.392 1.212-.56 1.585-.467.372.075.559.449.559 1.14 0 .767-.224 1.44-.671 1.982a6.193 6.193 0 0 1-1.753 1.44c-.242.149-.466.26-.671.373-.186.093-.373.187-.56.262Z"
					opacity={0.4}
				/>
				<defs>
					<linearGradient id="a" x1={-1.085} x2={46.592} y1={45.461} y2={88.012} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<linearGradient id="b" x1={27.405} x2={75.082} y1={13.466} y2={56.017} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<linearGradient id="c" x1={-7.742} x2={39.935} y1={41.381} y2={83.931} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<linearGradient id="d" x1={20.749} x2={68.426} y1={9.385} y2={51.936} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<linearGradient id="e" x1={77.529} x2={82.953} y1={57.682} y2={88.487} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
				</defs>
			</svg>
		);
	}

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={170} height={140} fill="none" {...props}>
			<path
				fill="url(#a)"
				d="M79.388 78.653c19.269-9.198 35.205-.59 38.024 19.233.49 3.447-2.702 7.98-6.57 9.827l-62.884 29.993c-3.867 1.847-7.06.362-6.593-3.542 2.819-22.509 18.755-46.332 38.023-55.511Z"
				opacity={0.5}
			/>
			<path
				fill="url(#b)"
				d="M57.907 52.887c0 13.692 9.693 20.167 21.645 14.454 11.952-5.694 21.621-21.424 21.621-35.097 0-13.692-9.692-20.166-21.644-14.453-11.953 5.694-21.622 21.423-21.622 35.096Z"
				opacity={0.5}
			/>
			<path
				fill="url(#c)"
				d="M70.07 72.94c19.267-9.198 35.204-.59 38.023 19.234.489 3.446-2.703 7.979-6.57 9.826l-62.884 29.993c-3.868 1.847-7.06.362-6.594-3.542 2.82-22.509 18.756-46.332 38.024-55.511Z"
				opacity={0.07}
			/>
			<path
				fill="url(#d)"
				d="M48.588 47.174c0 13.692 9.692 20.167 21.645 14.454 11.929-5.694 21.62-21.404 21.62-35.097 0-13.692-9.691-20.166-21.644-14.453C58.257 17.771 48.588 33.5 48.588 47.173Z"
				opacity={0.07}
			/>
			<path
				fill="url(#e)"
				d="M96.594 109.595c0 12.823 7.491 18.894 16.704 13.556 9.239-5.339 16.704-20.072 16.704-32.894 0-12.823-7.491-18.894-16.704-13.556-9.24 5.339-16.73 20.072-16.704 32.894Z"
				opacity={0.2}
			/>
			<path
				fill="#CE93D8"
				d="M121.311 128.383c-9.239 5.338-16.704-.733-16.73-13.555 0-12.823 7.491-27.556 16.704-32.894 9.239-5.339 16.704.733 16.704 13.555.026 12.823-7.465 27.556-16.678 32.894Zm2.871-36.662-3.21 1.858v3.114l-.94.628a31.96 31.96 0 0 0-2.27 1.7v20.203a12.49 12.49 0 0 0 2.531-.994c.235-.105.444-.21.679-.341v3.114l3.21-1.858v-3.14c1.566-1.099 2.793-2.329 3.68-3.69 1.149-1.779 1.723-3.742 1.723-5.94 0-1.073-.183-1.962-.522-2.617-.339-.654-.992-.968-1.932-.916 1.149-1.622 1.723-3.27 1.723-4.945 0-1.1-.157-1.937-.496-2.486-.313-.576-.757-.942-1.331-1.047-.574-.105-1.279-.053-2.062.235-.235.079-.496.183-.757.288l-.026-3.166Z"
				opacity={0.4}
			/>
			<path
				fill="#CE93D8"
				d="m122.824 104.125-1.644.943v-4.528c.261-.183.548-.392.887-.575.339-.21.653-.393.94-.576.887-.523 1.566-.733 2.035-.654.47.078.679.523.679 1.361a3.84 3.84 0 0 1-.679 2.198c-.443.628-1.174 1.23-2.218 1.831ZM121.18 113.992v-5.391l2.114-1.23c.965-.549 1.696-.785 2.218-.654.522.105.783.628.783 1.596 0 1.073-.313 2.015-.939 2.774-.627.759-1.436 1.439-2.454 2.015-.339.209-.652.366-.939.523-.261.131-.522.262-.783.367Z"
				opacity={0.4}
			/>
			<defs>
				<linearGradient id="a" x1={-0.119} x2={66.629} y1={63.646} y2={123.217} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient id="b" x1={39.768} x2={106.515} y1={18.852} y2={78.424} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient id="c" x1={-9.438} x2={57.31} y1={57.933} y2={117.504} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient id="d" x1={30.449} x2={97.196} y1={13.139} y2={72.711} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient id="e" x1={109.941} x2={117.534} y1={80.755} y2={123.882} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
			</defs>
		</svg>
	);
};
export default PlatformTrading;
