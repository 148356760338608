import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useCheckMobileScreen } from '../../../../hooks';

interface AddressProps {
	title: string;
	descriptions: Array<string>;
}
const Address: React.FC<AddressProps> = (props) => {
	const { title, descriptions } = props;
	const isMobile = useCheckMobileScreen();

	return (
		<Stack spacing={'6px'} flex={isMobile ? 1 : undefined}>
			<Typography variant={'body2'}>{title}</Typography>
			{descriptions.map((description) => (
				<Typography variant={'body2'} key={description} color={'text.secondary'}>
					{description}
				</Typography>
			))}
		</Stack>
	);
};

export default Address;
