import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useCheckMobileScreen } from '../../../../../../hooks';

interface BestFitsItemProps {
	title: string;
	list: Array<string>;
	button: {
		title: string;
		href: string;
	};
}

const BestFitsItem: React.FC<BestFitsItemProps> = (props) => {
	const { title, list, button } = props;
	const isMobile = useCheckMobileScreen();

	return (
		<Stack spacing={isMobile ? '12px' : 2} pt={'10px'} alignItems={isMobile ? 'center' : 'flex-start'}>
			<Typography variant={'h5'} paragraph sx={{ marginBottom: 0 }} color={'primary.main'}>
				{title}
			</Typography>
			<Stack direction={'row'} alignItems={'center'} spacing={'12px'}>
				{list.map((item, index) => {
					return (
						<React.Fragment key={item}>
							<Typography variant={'body1'}>{item}</Typography>
							{index < list.length - 1 && (
								<svg xmlns="http://www.w3.org/2000/svg" width={4} height={4} fill="none">
									<circle cx={2} cy={2} r={2} fill="#fff" />
								</svg>
							)}
						</React.Fragment>
					);
				})}
			</Stack>
			<Button
				style={{ minWidth: 200 }}
				fullWidth={isMobile}
				size={'large'}
				href={button.href}
				variant={'outlined'}
				target={'_blank'}
			>
				{button.title}
			</Button>
		</Stack>
	);
};

export default BestFitsItem;
