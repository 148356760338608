import styled from '@emotion/styled';
import { Card, CardHeader } from '@mui/material';

export const CompanyCardContainer = styled(Card)`
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0;
`;

export const CompanyCardHeader = styled(CardHeader)`
	flex: 1;
	align-items: flex-start;
`;
