import React from 'react';
import { Grid, Stack } from '@mui/material';
import { CustomBigTypography } from '../../../../styles/common.styles';
import ServiceItem from './components/ServiceItem';
import PlatformTrading from './components/icons/PlatformTrading';
import CompanyLiquidityPrograms from './components/icons/CompanyLiquidityPrograms';
import SecondaryMarketData from './components/icons/SecondaryMarketData';
import PreIPOFundAccess from './components/icons/PreIPOFundAccess';
import ServicesCircle from './components/icons/ServicesCircle';
import { useCheckMobileScreen } from '../../../../hooks';

const OurServicesSection: React.FC = () => {
	const isMobile = useCheckMobileScreen();

	const platformTrading = (
		<ServiceItem
			icon={<PlatformTrading />}
			title={'Platform Trading'}
			description={
				'Supporting all ShareWeller’s in their brokerage goals across all share classes, both direct transfer and SPV’s'
			}
		/>
	);

	const companyLiquidityPrograms = (
		<ServiceItem
			icon={<CompanyLiquidityPrograms />}
			title={'Company Liquidity Programs'}
			description={'Providing issuers an independent, market-confidential tender program choice.'}
		/>
	);

	const secondaryMarketData = (
		<ServiceItem
			icon={<SecondaryMarketData />}
			title={'Secondary Market Data'}
			description={
				'Empowering shareholders and investors searching for best-in-class, real-time, market aggregated price and trading data for all classes.'
			}
		/>
	);

	const preIpoFundAccess = (
		<ServiceItem
			icon={<PreIPOFundAccess />}
			title={'Pre-IPO Fund Access'}
			description={
				'Servicing accredited investors with access to curated Pre-IPO companies through SWVC-affiliated funds.'
			}
		/>
	);

	const renderDesktopContent = () => {
		return (
			<Grid container spacing={3}>
				<Grid item xs={4} />
				<Grid item xs={4}>
					{platformTrading}
				</Grid>
				<Grid item xs={4} />
				<Grid item xs={4}>
					{companyLiquidityPrograms}
				</Grid>
				<Grid item xs={4}>
					<Stack pt={'42px'} alignItems={'center'}>
						<ServicesCircle />
					</Stack>
				</Grid>
				<Grid item xs={4}>
					{secondaryMarketData}
				</Grid>
				<Grid item xs={4} />
				<Grid item xs={4}>
					{preIpoFundAccess}
				</Grid>
				<Grid item xs={4} />
			</Grid>
		);
	};

	const renderMobileContent = () => {
		return (
			<Stack direction={'column'} spacing={'40px'}>
				{companyLiquidityPrograms}
				{platformTrading}
				{secondaryMarketData}
				{preIpoFundAccess}
			</Stack>
		);
	};

	return (
		<Stack
			spacing={isMobile ? '46px' : '80px'}
			id={'our-services-section'}
			pt={isMobile ? '120px' : '180px'}
			alignItems={'center'}
		>
			<CustomBigTypography>Our services</CustomBigTypography>
			{isMobile ? renderMobileContent() : renderDesktopContent()}
		</Stack>
	);
};

export default OurServicesSection;
