import { Box, Grid, Stack } from '@mui/material';
import { CustomBigTypography } from '../../../../styles/common.styles';
import BestFitsItem from './components/BestFitsItem';
import { endpoints } from '../../../../utils/endpoints';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useCheckMobileScreen } from '../../../../hooks';

const BestFitsSection = () => {
	const isMobile = useCheckMobileScreen();

	const title = <CustomBigTypography>Best fits for</CustomBigTypography>;
	const shareholders = (
		<BestFitsItem
			title={'Shareholders'}
			list={['Employees', 'Executives', 'Founders']}
			button={{ title: 'start now', href: endpoints.appSignup }}
		/>
	);
	const institutionalFirms = (
		<BestFitsItem
			title={'Institutional Firms'}
			list={['VC', 'Hedge', 'Asset Managers', 'LP']}
			button={{ title: 'request access', href: endpoints.appSignup }}
		/>
	);

	const renderDesktopContent = () => {
		return (
			<Grid container spacing={3} pt={'180px'} pb={'180px'}>
				<Grid item xs={3}>
					<AnimationOnScroll animateIn="animate__slideInLeft">{title}</AnimationOnScroll>
				</Grid>
				<Grid item xs={5}>
					{shareholders}
				</Grid>
				<Grid item xs={4}>
					{institutionalFirms}
				</Grid>
			</Grid>
		);
	};

	const renderMobileContent = () => {
		return (
			<Stack direction={'column'} spacing={'40px'} pt={'120px'} pb={'120px'} alignItems={'center'} width={'100%'}>
				{title}
				<Stack direction={'column'} spacing={'50px'}>
					{shareholders}
					{institutionalFirms}
				</Stack>
			</Stack>
		);
	};

	return <Box id={'best-fits-for-section'}>{isMobile ? renderMobileContent() : renderDesktopContent()}</Box>;
};

export default BestFitsSection;
