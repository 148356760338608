import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './layout/MainLayout';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { darkTheme } from './styles/theme';
import { CompaniesContextProvider } from './context';
import './index.css';
import 'animate.css/animate.min.css';
import DocumentPage from './pages/DocumentPage';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<ThemeProvider theme={darkTheme}>
				<CssBaseline enableColorScheme />
				<MainLayout>
					<Routes>
						<Route
							path="/"
							element={
								<CompaniesContextProvider>
									<Home />
								</CompaniesContextProvider>
							}
						/>
						<Route path="/privacy" element={<DocumentPage />} />
						<Route path="/terms" element={<DocumentPage />} />
						<Route path="/careers" element={<DocumentPage />} />
					</Routes>
				</MainLayout>
			</ThemeProvider>
		</BrowserRouter>
	</React.StrictMode>
);
