export const endpoints = {
	appLogin: `${process.env.REACT_APP_APP_URL}/`,
	appSignup: `${process.env.REACT_APP_APP_URL}/signup`,
	getAllCompaniesNoToken: `${process.env.REACT_APP_SERVER_URL}/api/v2/public/getCompanies`,
	subscribe: `${process.env.REACT_APP_SERVER_URL}/api/v2/public/newsletterSignup`,
};

export const socialLinks = {
	linkedin: 'https://www.linkedin.com/company/saxon-weber/',
	twitter: 'https://twitter.com/saxonweber_',
	mailto: 'mailto:info@saxonweber.com',
	mail: 'info@saxonweber.com',
};
