import { DocumentBlock } from '../documents';
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useCheckMobileScreen } from '../../../hooks';

const Block: React.FC<DocumentBlock> = (props) => {
	const { variant, paragraphs, title } = props;
	const isMobile = useCheckMobileScreen();

	return (
		<Stack
			spacing={variant === 'h1' && !isMobile ? '28px' : '22px'}
			pt={variant === 'h1' && !isMobile ? '70px' : '50px'}
		>
			<Typography variant={variant === 'h1' ? 'h5' : 'h6'} fontWeight={500}>
				{title}
			</Typography>
			<Stack spacing={3}>
				{paragraphs.map((paragraph, index) => (
					<Typography key={index}>{paragraph}</Typography>
				))}
			</Stack>
		</Stack>
	);
};

export default Block;
