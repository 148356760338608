import { Box, Grid, Paper, Stack, useTheme } from '@mui/material';
import { BlurCircle, CustomBigTypography } from '../../../../styles/common.styles';
import { ToggleButton } from '../../../../components/ToggleButton/ToggleButton';
import React, { useState } from 'react';
import {
	investorScreenshots,
	investorSnakeSteps,
	investorSteps,
	shareholderScreenshots,
	shareholderSnakeSteps,
	shareholderSteps,
} from './steps';
import Step from './components/Step';
import Carousel from 'react-material-ui-carousel';
import { Screenshot } from './style';
import SnakeCurve from './components/SnakeCurve';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useCheckMobileScreen } from '../../../../hooks';

const HowItWorksSection = () => {
	const [stakeholder, setStakeholder] = useState<'shareholder' | 'investor'>('shareholder');
	const [selectedStep, setSelectedStep] = useState<number>(1);
	const defaultTheme = useTheme();
	const isMobile = useCheckMobileScreen();

	const toggleStakeholder = () => {
		setStakeholder(stakeholder === 'shareholder' ? 'investor' : 'shareholder');
		setSelectedStep(1);
	};

	return (
		<Stack
			spacing={isMobile ? '42px' : '100px'}
			pt={isMobile ? '120px' : '180px'}
			pb={isMobile ? '100px' : '200px'}
			id={'how-it-works-section'}
		>
			<Stack spacing={isMobile ? 3 : 4} alignItems={'center'}>
				<CustomBigTypography>How it works</CustomBigTypography>
				<Box width={'100%'}>
					<Grid container spacing={3}>
						{!isMobile && <Grid item xs={4} />}
						<Grid item xs={isMobile ? 12 : 4}>
							<ToggleButton
								leftLabel="for shareholders"
								rightLabel="for investors"
								callback={toggleStakeholder}
								position={stakeholder === 'shareholder' ? 'left' : 'right'}
							/>
						</Grid>
					</Grid>
				</Box>
			</Stack>
			<Box width={'100%'}>
				<Grid
					container
					spacing={3}
					direction={isMobile ? 'column-reverse' : undefined}
					rowSpacing={isMobile ? '42px' : undefined}
				>
					<Grid item xs={isMobile ? 12 : 5}>
						<AnimationOnScroll animateIn="animate__slideInLeft">
							<Stack spacing={isMobile ? '14px' : '2px'}>
								{(stakeholder === 'shareholder' ? shareholderSteps : investorSteps).map((step) => {
									return (
										<Step
											{...step}
											selected={step.index === selectedStep}
											key={step.index}
											onClick={() => setSelectedStep(step.index)}
										/>
									);
								})}
							</Stack>
						</AnimationOnScroll>
					</Grid>
					{!isMobile && <Grid item xs={1} />}
					<Grid item xs={isMobile ? 12 : 6}>
						<Paper elevation={1} sx={{ boxShadow: 'none', borderRadius: '16px', p: 2, position: 'relative' }}>
							<Carousel
								animation={'slide'}
								indicatorIconButtonProps={{ style: { color: defaultTheme.palette.grey['800'] } }}
								activeIndicatorIconButtonProps={{ style: { color: defaultTheme.palette.grey['400'] } }}
								height={isMobile ? 240 : 340}
							>
								{(stakeholder === 'shareholder' ? shareholderScreenshots : investorScreenshots).map((screenshot) => {
									return (
										<Box
											key={screenshot}
											display={'flex'}
											alignItems={'center'}
											justifyContent={'center'}
											height={'100%'}
										>
											<Screenshot src={screenshot} alt={'application'} />
										</Box>
									);
								})}
							</Carousel>
							<BlurCircle
								style={{
									width: isMobile ? 175 : 375,
									height: isMobile ? 175 : 375,
									bottom: isMobile ? -50 : -60,
									right: isMobile ? -50 : -40,
								}}
							/>
						</Paper>
					</Grid>
				</Grid>
			</Box>
			{!isMobile && (
				<Box pt={'73px'}>
					<SnakeCurve snakeSteps={stakeholder === 'shareholder' ? shareholderSnakeSteps : investorSnakeSteps} />
				</Box>
			)}
		</Stack>
	);
};

export default HowItWorksSection;
