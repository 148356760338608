import styled from 'styled-components';
import { Stack } from '@mui/material';
import { headerHeight } from '../../styles/common.styles';
import { useCheckMobileScreen } from '../../hooks';

export const HeaderWrapper = styled(Stack).attrs({
	alignItems: 'center',
	justifyContent: 'space-between',
	direction: 'row',
	bgcolor: 'background.default',
})(() => {
	const isMobile = useCheckMobileScreen();

	return {
		position: 'sticky' as const,
		left: 0,
		top: 0,
		height: headerHeight,
		paddingLeft: 32,
		paddingRight: 32,
		zIndex: isMobile ? 2 : 1501,
	};
});
