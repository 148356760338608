import React from 'react';
import { Stack, Typography } from '@mui/material';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useCheckMobileScreen } from '../../../../../../hooks';

interface ServiceItemProps {
	icon: React.ReactNode;
	title: string;
	description: string;
}

const ServiceItem: React.FC<ServiceItemProps> = (props) => {
	const { icon, title, description } = props;
	const isMobile = useCheckMobileScreen();

	return (
		<Stack spacing={isMobile ? '28px' : '36px'} alignItems={'center'}>
			{isMobile ? icon : <AnimationOnScroll animateIn="animate__bounceIn">{icon}</AnimationOnScroll>}
			<Stack spacing={isMobile ? 1 : 2} alignItems={isMobile ? 'center' : undefined}>
				<Typography variant={'h5'} paragraph={true} marginBottom={0}>
					{title}
				</Typography>
				<Typography variant={isMobile ? 'body1' : 'body2'} color={'text.secondary'}>
					{description}
				</Typography>
			</Stack>
		</Stack>
	);
};

export default ServiceItem;
