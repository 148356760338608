import { Box, Checkbox, Grid, ListItemText, MenuItem, Skeleton, Stack, TextField, Typography } from '@mui/material';
import CompanyCard from '../CompanyCard';
import { Apartment } from '@mui/icons-material';
import React, { useMemo, useState } from 'react';
import { Company } from '../../../../../../types/company';
import { useCheckMobileScreen } from '../../../../../../hooks';
import { formatValuation } from '../../../../../../utils/stringUtils';
import { NotFoundContainer } from './style';
import SelectInput from '../../../../../../components/SelectInput';

type ValuationOptionType = {
	valueFrom: number;
	valueTo: number | null;
	label: string;
};

type FilterType = {
	name: string;
	sector: Array<string>;
	valuation: Array<string>;
};

const valuationOptions: Array<ValuationOptionType> = [
	{ valueFrom: 0, valueTo: 500000000, label: '< $500M' },
	{ valueFrom: 500000000, valueTo: 1000000000, label: '$500M - $1B' },
	{ valueFrom: 1000000000, valueTo: 5000000000, label: '$1B - $5B' },
	{ valueFrom: 5000000000, valueTo: 1000000000, label: '$5B - $10B' },
	{ valueFrom: 1000000000, valueTo: null, label: '> $10B' },
];

interface CompanyListProps {
	companies: Array<Company>;
	loading: boolean;
}

const CompanyList: React.FC<CompanyListProps> = (props) => {
	const { companies = [], loading } = props;
	const isMobile = useCheckMobileScreen();
	const [filter, setFilters] = useState<FilterType>({
		name: '',
		sector: [],
		valuation: [],
	});

	const filteredCompanies = useMemo(() => {
		return companies.filter((company) => {
			const isName = filter.name ? company['Legal Name'].toLowerCase().includes(filter.name.toLowerCase()) : true;
			const isSector = filter.sector.length ? filter.sector.includes(company.Sectors) : true;

			let isValuation = true;
			if (filter.valuation.length) {
				isValuation = false;

				filter.valuation.forEach((valuation) => {
					if (isValuation) return;
					const { valueFrom, valueTo } = valuationOptions.find((val) => val.label === valuation) ?? {};
					isValuation =
						(valueFrom ?? 0) <= company.valuation && (valueTo === null || (valueTo ?? 0) >= company.valuation);
				});
			}

			return isName && isSector && isValuation;
		});
	}, [companies, filter]);

	const handleChange = (evt: any) => {
		const name = evt.target.name;
		const value = evt.target.value;

		switch (name) {
			case 'name':
				setFilters((prev) => ({
					...prev,
					[name]: value,
				}));
				break;
			case 'favorite':
				setFilters((prev) => ({
					...prev,
					[name]: evt.target.checked,
				}));
				break;
			default:
				setFilters((prev) => ({
					...prev,
					[name]: value === 'string' ? value.split(',') : value,
				}));
				break;
		}
	};

	const getSelectOptions = (allCompaniesFilters: Array<any>, selectedCompaniesFilters: Array<any>) => {
		return [...new Set(allCompaniesFilters)].map((filter) => (
			<MenuItem key={filter} value={filter}>
				<Checkbox checked={selectedCompaniesFilters.indexOf(filter) > -1} />
				<ListItemText primary={filter} />
			</MenuItem>
		));
	};

	const renderContent = () => {
		if (loading) {
			return (
				<Stack spacing={1}>
					<Skeleton variant="text" sx={{ fontSize: '14px', bgcolor: 'grey.900' }} width={150} />
					<Box>
						<Grid container spacing={2} alignItems={'stretch'}>
							{Array.from(Array(8).keys()).map((_, index) => (
								<Grid key={index} item xs={isMobile ? 12 : 4}>
									<Skeleton variant="rectangular" height={isMobile ? 140 : 180} sx={{ bgcolor: 'grey.900' }} />
								</Grid>
							))}
						</Grid>
					</Box>
				</Stack>
			);
		} else {
			if (filteredCompanies.length) {
				return (
					<Stack spacing={1}>
						<Typography variant={'body2'}>{`Found ${filteredCompanies.length} companies`}</Typography>
						<Box>
							<Grid container spacing={2} alignItems={'stretch'}>
								{filteredCompanies.map((company, index) => (
									<Grid key={index} item xs={isMobile ? 12 : 4}>
										<CompanyCard
											key={company['Record Id']}
											companyName={company['Legal Name']}
											sectorIcon={Apartment}
											sectorName={company.Sectors}
											valuation={formatValuation(company.valuation)}
											elevation={8}
										/>
									</Grid>
								))}
							</Grid>
						</Box>
					</Stack>
				);
			} else {
				return (
					<NotFoundContainer>
						<Stack spacing={3} alignItems={'center'}>
							<img src={'/illustrations/dark/notFound.svg'} style={{ height: isMobile ? 140 : 200 }} alt="Not found" />
							<Stack spacing={1} alignItems={'center'}>
								<Typography variant={'h5'}>No companies found</Typography>
								<Typography variant={'body2'} textAlign={'center'}>
									We couldn't find what you searched for. Change your search criteria.
								</Typography>
							</Stack>
						</Stack>
					</NotFoundContainer>
				);
			}
		}
	};

	return (
		<Stack spacing={4} height={filteredCompanies.length ? undefined : '100%'}>
			<Stack spacing={1} direction={isMobile ? 'column' : 'row'}>
				<TextField
					fullWidth
					variant={'outlined'}
					name={'name'}
					value={filter.name}
					onChange={handleChange}
					label={'Company name'}
					size={isMobile ? 'medium' : 'small'}
				/>
				{!isMobile && (
					<SelectInput
						multiple
						name={'sector'}
						label={'Sector'}
						value={filter.sector}
						onChange={handleChange}
						size={'small'}
						fullWidth
						renderValue={(selected: any) => selected.join(', ')}
					>
						{getSelectOptions(
							companies.map((company) => company.Sectors),
							filter.sector
						)}
					</SelectInput>
				)}
				{!isMobile && (
					<SelectInput
						multiple
						fullWidth
						name={'valuation'}
						label={'Valuation'}
						value={filter.valuation}
						size={'small'}
						onChange={handleChange}
						renderValue={(selected: any) => selected.join(', ')}
					>
						{getSelectOptions(
							valuationOptions.map((val) => val.label),
							filter.valuation
						)}
					</SelectInput>
				)}
			</Stack>
			{renderContent()}
		</Stack>
	);
};

export default CompanyList;
