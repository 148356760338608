import React from 'react';
import { HeaderWrapper } from './style';
import Logo from '../../components/Logo';
import { Button, Stack } from '@mui/material';
import { endpoints } from '../../utils/endpoints';
import { Link } from 'react-router-dom';
import { useCheckMobileScreen } from '../../hooks';

const Header: React.FC = () => {
	const isMobile = useCheckMobileScreen();

	return (
		<HeaderWrapper>
			<Link to={'/'}>
				<Logo size={isMobile ? 'small' : 'long'} />
			</Link>
			<Stack direction={'row'} spacing={1}>
				<Button
					variant="text"
					sx={{ minWidth: 100 }}
					href={endpoints.appLogin}
					target={'_blank'}
					size={isMobile ? 'large' : 'medium'}
				>
					Log in
				</Button>
				<Button
					variant="contained"
					sx={{ minWidth: 100 }}
					href={endpoints.appSignup}
					target={'_blank'}
					size={isMobile ? 'large' : 'medium'}
				>
					sign up
				</Button>
			</Stack>
		</HeaderWrapper>
	);
};

export default Header;
