import * as React from 'react';
import { SVGProps } from 'react';
import { useCheckMobileScreen } from '../../../../../../hooks';
const CompanyLiquidityPrograms = (props: SVGProps<SVGSVGElement>) => {
	const isMobile = useCheckMobileScreen();

	if (isMobile) {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width={120} height={100} fill="none" {...props}>
				<path fill="url(#a)" d="M93.769 0 20.16 42.537l.011 57.43L93.78 57.432 93.77 0Z" opacity={0.1} />
				<path fill="url(#b)" d="M93.769 0 20.257 42.484v7.79L93.769 7.79V0Z" opacity={0.2} />
				<path fill="#AB47BC" d="M100.714 26.016 81.686 37.009v5.282l19.028-10.993v-5.282Z" />
				<path fill="#CE93D8" d="M48.253 55.47 29.236 66.463v5.282l19.017-10.993V55.47Z" />
				<path fill="url(#c)" d="M60.732 62.584 23.596 84.046V100l37.136-21.462V62.584Z" opacity={0.15} />
				<path fill="url(#d)" d="M57.136 60.784 20 82.246V98.2l37.136-21.462V60.784Z" opacity={0.07} />
				<path
					fill="#fff"
					d="m57.981 67.417-5.597 3.236v5.282l5.597-3.236v-5.282ZM51.09 71.585l-5.598 3.236v5.282l5.597-3.236v-5.282ZM44.197 75.495 38.6 78.731v5.282l5.597-3.236v-5.282ZM37.305 79.663 31.708 82.9v5.282l5.597-3.236v-5.282ZM88.803 21.59l-42.23 24.408v1.8l42.23-24.408v-1.8ZM88.803 25.223l-36.44 21.054v1.8l36.44-21.054v-1.8ZM88.803 28.811 57.8 46.727v1.8l31.004-17.914v-1.8Z"
					opacity={0.15}
				/>
				<path fill="url(#e)" d="m98.97 38.809-30.918 17.86v15.955L98.97 54.763V38.809Z" opacity={0.15} />
				<path fill="url(#f)" d="M95.374 37.009 64.456 54.87v15.954l30.918-17.861V37.009Z" opacity={0.07} />
				<path
					fill="#fff"
					d="M96.862 43.866 72.29 58.063v1.8l24.56-14.197.012-1.8ZM96.862 47.498l-19.67 11.368v1.8l19.67-11.368v-1.8Z"
					opacity={0.15}
				/>
				<path
					fill="#fff"
					d="M85.764 8.518c0 1.168.685 1.714 1.52 1.232.834-.482 1.519-1.821 1.519-2.978 0-1.168-.685-1.715-1.52-1.233-.845.483-1.52 1.822-1.52 2.98Z"
					opacity={0.2}
				/>
				<path
					fill="url(#g)"
					d="M87.765 69.86c0 2.121.47 3.482 1.413 4.071.941.59 2.204.429 3.788-.492 1.584-.922 2.847-2.208 3.789-3.89.941-1.671 1.412-3.579 1.412-5.7 0-2.122-.47-3.482-1.412-4.072-.942-.589-2.205-.428-3.789.493-1.584.91-2.847 2.207-3.788 3.89-.942 1.671-1.413 3.568-1.413 5.7Zm3.318-1.918c0-1.157.17-2.09.524-2.818.353-.729.803-1.243 1.348-1.564.568-.333 1.028-.333 1.381-.022.332.332.503 1.072.503 2.218 0 1.157-.171 2.1-.503 2.829-.353.728-.813 1.253-1.38 1.575-.546.321-.996.321-1.349 0-.343-.322-.524-1.072-.524-2.218Zm-9.044-.814-4.077 2.357L89.445 86.37l4.078-2.357-11.484-16.886ZM73.264 89.67c0 2.122.47 3.482 1.413 4.072.941.59 2.204.428 3.788-.493 1.584-.91 2.847-2.207 3.788-3.89.942-1.671 1.413-3.578 1.413-5.7 0-2.121-.47-3.482-1.412-4.071-.942-.59-2.205-.429-3.789.493-1.584.91-2.847 2.207-3.788 3.889-.942 1.671-1.413 3.579-1.413 5.7Zm3.317-1.918c0-1.157.172-2.09.525-2.818.353-.728.803-1.243 1.348-1.564.568-.332 1.028-.332 1.38-.021.333.332.504 1.071.504 2.218 0 1.157-.171 2.1-.503 2.828-.353.729-.813 1.254-1.38 1.575-.546.322-.996.322-1.35 0-.342-.31-.524-1.06-.524-2.218Z"
					opacity={0.3}
				/>
				<defs>
					<linearGradient id="a" x1={42.624} x2={70.108} y1={35.658} y2={63.055} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<linearGradient id="b" x1={75.528} x2={47.566} y1={-6.265} y2={41.044} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<linearGradient id="c" x1={38.714} x2={45.081} y1={75.311} y2={86.329} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<linearGradient id="d" x1={35.115} x2={41.482} y1={73.51} y2={84.527} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<linearGradient id="e" x1={80.063} x2={86.429} y1={49.734} y2={60.751} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<linearGradient id="f" x1={76.464} x2={82.831} y1={47.932} y2={58.95} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
					<linearGradient id="g" x1={79.903} x2={95.176} y1={68.607} y2={89.992} gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset={1} stopColor="#fff" stopOpacity={0} />
					</linearGradient>
				</defs>
			</svg>
		);
	}

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={170} height={140} fill="none" {...props}>
			<path fill="url(#a)" d="M103.276 0 .225 59.552l.015 80.403L103.29 80.403 103.276 0Z" opacity={0.1} />
			<path fill="url(#b)" d="M103.276 0 .359 59.477v10.905l102.917-59.477V0Z" opacity={0.2} />
			<path fill="#AB47BC" d="m113 36.421-26.64 15.39v7.396L113 43.817V36.42Z" />
			<path fill="#CE93D8" d="M39.555 77.658 12.93 93.048v7.396l26.625-15.391v-7.395Z" />
			<path fill="url(#c)" d="m57.024 87.618-51.99 30.046V140l51.99-30.046V87.618Z" opacity={0.15} />
			<path fill="url(#d)" d="M51.99 85.098 0 115.144v22.336l51.99-30.046V85.098Z" opacity={0.07} />
			<path
				fill="#fff"
				d="m53.174 94.383-7.836 4.53v7.396l7.836-4.53v-7.396ZM43.525 100.219l-7.836 4.53v7.395l7.836-4.53v-7.395ZM33.876 105.694l-7.836 4.53v7.395l7.836-4.53v-7.395ZM24.227 111.529l-7.836 4.53v7.395l7.836-4.53v-7.395ZM96.324 30.226 37.202 64.397v2.52l59.122-34.17v-2.52ZM96.324 35.311 45.308 64.787v2.52l51.016-29.476v-2.52ZM96.324 40.336 52.919 65.417v2.52l43.405-25.08v-2.52Z"
				opacity={0.15}
			/>
			<path fill="url(#e)" d="M110.558 54.332 67.272 79.338v22.336l43.286-25.006V54.332Z" opacity={0.15} />
			<path fill="url(#f)" d="M105.523 51.812 62.238 76.818v22.335l43.285-25.005V51.812Z" opacity={0.07} />
			<path
				fill="#fff"
				d="m107.606 61.412-34.4 19.876v2.52l34.385-19.876.015-2.52ZM107.606 66.497 80.068 82.413v2.52l27.538-15.916v-2.52Z"
				opacity={0.15}
			/>
			<path
				fill="#fff"
				d="M92.07 11.925c0 1.635.958 2.4 2.127 1.725 1.168-.675 2.127-2.55 2.127-4.17 0-1.635-.959-2.4-2.127-1.725-1.184.675-2.128 2.55-2.128 4.17Z"
				opacity={0.2}
			/>
			<path
				fill="url(#g)"
				d="M94.871 97.803c0 2.971.66 4.876 1.978 5.701 1.318.825 3.086.6 5.304-.69 2.217-1.29 3.985-3.09 5.304-5.446 1.318-2.34 1.977-5.01 1.977-7.98 0-2.97-.659-4.875-1.977-5.7-1.319-.825-3.087-.6-5.304.69-2.218 1.275-3.986 3.09-5.304 5.445-1.319 2.34-1.978 4.995-1.978 7.98Zm4.645-2.685c0-1.62.24-2.925.734-3.945s1.124-1.74 1.888-2.19c.794-.465 1.438-.465 1.932-.03.465.465.705 1.5.705 3.105 0 1.62-.24 2.94-.705 3.96-.494 1.02-1.138 1.755-1.932 2.205-.764.45-1.394.45-1.888 0-.48-.45-.734-1.5-.734-3.105Zm-12.66-1.14-5.71 3.3 16.077 23.641 5.709-3.3-16.077-23.64ZM74.568 125.54c0 2.971.66 4.876 1.978 5.701 1.319.825 3.087.6 5.304-.69 2.217-1.275 3.985-3.091 5.304-5.446 1.318-2.34 1.978-5.01 1.978-7.98 0-2.97-.66-4.875-1.978-5.7-1.319-.825-3.087-.6-5.304.69-2.218 1.275-3.986 3.09-5.304 5.445-1.318 2.34-1.978 5.01-1.978 7.98Zm4.645-2.685c0-1.62.24-2.925.734-3.945.495-1.02 1.124-1.74 1.888-2.19.794-.465 1.438-.465 1.933-.03.464.465.704 1.5.704 3.105 0 1.62-.24 2.94-.704 3.96-.495 1.02-1.139 1.755-1.933 2.205-.764.45-1.393.45-1.888 0-.48-.435-.734-1.485-.734-3.105Z"
				opacity={0.3}
			/>
			<defs>
				<linearGradient id="a" x1={31.673} x2={70.151} y1={49.922} y2={88.278} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient id="b" x1={77.739} x2={38.592} y1={-8.772} y2={57.461} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient id="c" x1={26.2} x2={35.113} y1={105.436} y2={120.861} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient id="d" x1={21.161} x2={30.075} y1={102.914} y2={118.338} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient id="e" x1={84.088} x2={93.001} y1={69.627} y2={85.051} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient id="f" x1={79.049} x2={87.963} y1={67.105} y2={82.529} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient id="g" x1={83.864} x2={105.246} y1={96.049} y2={125.989} gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
			</defs>
		</svg>
	);
};
export default CompanyLiquidityPrograms;
