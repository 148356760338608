import React, { useState } from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Paper,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { useCompaniesContext } from '../../../../context';
import CompanyCard from './components/CompanyCard';
import { Apartment } from '@mui/icons-material';
import { formatValuation } from '../../../../utils/stringUtils';
import CompanyList from './components/CompanyList';
import { BlurCircle } from '../../../../styles/common.styles';
import { useCheckMobileScreen } from '../../../../hooks';
import { MobileCompaniesContainer } from './style';

const CompaniesSection: React.FC = () => {
	const isMobile = useCheckMobileScreen();
	const { companies, companiesLoading } = useCompaniesContext();
	const [isModalOpen, setModalOpen] = useState<boolean>(false);
	const previewCardsAmount = isMobile ? 2 : 9;

	const renderDesktopPreviewCompanies = () => {
		let content;
		if (companiesLoading) {
			content = Array.from(Array(previewCardsAmount).keys()).map((_, index) => (
				<Grid item xs={4} key={index}>
					<Skeleton variant="rectangular" height={150} sx={{ bgcolor: 'grey.900' }} />
				</Grid>
			));
		} else {
			content = companies.slice(0, previewCardsAmount).map((company) => (
				<Grid item xs={4} key={company['Legal Name']}>
					<CompanyCard
						companyName={company['Legal Name']}
						sectorIcon={Apartment}
						sectorName={company.Sectors}
						valuation={formatValuation(company.valuation)}
						elevation={6}
					/>
				</Grid>
			));
		}

		return (
			<Grid container spacing={3}>
				{content}
			</Grid>
		);
	};

	const renderMobilePreviewCompanies = () => {
		let content;
		if (companiesLoading) {
			content = Array.from(Array(previewCardsAmount).keys()).map((_, index) => (
				<Skeleton
					variant="rectangular"
					style={{ flexShrink: 0 }}
					key={index}
					height={122}
					width={255}
					sx={{ bgcolor: 'grey.900' }}
				/>
			));
		} else {
			content = companies.slice(0, previewCardsAmount).map((company) => (
				<Box width={255} key={company['Legal Name']} flexShrink={0}>
					<CompanyCard
						companyName={company['Legal Name']}
						sectorIcon={Apartment}
						sectorName={company.Sectors}
						valuation={formatValuation(company.valuation)}
						elevation={6}
					/>
				</Box>
			));
		}

		return <MobileCompaniesContainer>{content}</MobileCompaniesContainer>;
	};

	return (
		<Stack pt={isMobile ? '120px' : '180px'} id={'companies-section'}>
			<Paper elevation={1} sx={{ boxShadow: 'none', position: 'relative' }}>
				<Grid container spacing={isMobile ? 0 : 3} pt={isMobile ? '40px' : '64px'} pb={isMobile ? '20px' : '52px'}>
					<Grid item xs={isMobile ? 0 : 1} />
					<Grid item xs={isMobile ? 12 : 10} pl={isMobile ? '20px' : undefined} pr={isMobile ? '20px' : undefined}>
						<Stack spacing={isMobile ? 4 : '42px'} alignItems={'center'}>
							<Typography variant={'body1'}>We have 300+ private companies</Typography>
							{isMobile ? renderMobilePreviewCompanies() : renderDesktopPreviewCompanies()}
							<Button size={'large'} variant={'text'} onClick={() => setModalOpen(true)}>
								See All Companies
							</Button>
						</Stack>
					</Grid>
					<Grid item xs={isMobile ? 0 : 1} />
				</Grid>
				<BlurCircle
					style={{ width: isMobile ? 198 : 478, height: isMobile ? 198 : 478, top: isMobile ? -30 : -70, right: -60 }}
				/>
			</Paper>

			<Dialog
				open={isModalOpen}
				onClose={() => setModalOpen(false)}
				scroll={'paper'}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
				maxWidth={'md'}
				fullWidth={true}
				PaperProps={{
					elevation: 1,
					sx: {
						height: `calc(100% - ${isMobile ? '68px' : '140px'})`,
					},
				}}
			>
				<DialogTitle id="scroll-dialog-title">Browse Companies</DialogTitle>
				<DialogContent dividers={true} sx={{ overflowY: 'scroll' }}>
					<CompanyList companies={companies} loading={companiesLoading} />
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setModalOpen(false)}>Close</Button>
				</DialogActions>
			</Dialog>
		</Stack>
	);
};

export default CompaniesSection;
