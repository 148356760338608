import React from 'react';
import { Stack, Typography } from '@mui/material';
import { CustomBigTypography } from '../../../../../../styles/common.styles';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';

interface StatisticItemProps {
	value: string;
	title: string;
	sx?: SxProps<Theme>;
}

const StatisticItem: React.FC<StatisticItemProps> = (props) => {
	const { value, title, sx } = props;

	return (
		<Stack spacing={1} alignItems={'center'} sx={sx}>
			<CustomBigTypography color={'primary.main'} paragraph sx={{ marginBottom: 0 }}>
				{value}
			</CustomBigTypography>
			<Typography variant={'body2'} color={'text.secondary'} align={'center'}>
				{title}
			</Typography>
		</Stack>
	);
};

export default StatisticItem;
