import React from 'react';
import { Container, Stack } from '@mui/material';
import HeroSection from './sections/HeroSection';
import StatisticsSection from './sections/StatisticsSection';
import OurServicesSection from './sections/OurServicesSection';
import CompaniesSection from './sections/CompaniesSection';
import BestFitsSection from './sections/BestFitsSection';
import InsightSection from './sections/InsightSection';
import HowItWorksSection from './sections/HowItWorksSection';
import SubscribeSection from './sections/SubscribeSection';
import FooterSection from '../../components/FooterSection';

const Home = () => {
	return (
		<Stack>
			<Container>
				<HeroSection />
			</Container>
			<StatisticsSection />
			<Container>
				<OurServicesSection />
				<CompaniesSection />
				<BestFitsSection />
			</Container>
			<InsightSection />
			<Container>
				<HowItWorksSection />
				{/* <SubscribeSection /> */}
			</Container>
			<FooterSection />
		</Stack>
	);
};

export default Home;
