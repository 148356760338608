import styled from 'styled-components';
import { Stack, Typography, useTheme } from '@mui/material';

export const StepTitle = styled(Typography).attrs({
	variant: 'h6',
	paragraph: true,
})(() => {
	return {
		marginBottom: '0 !important',
		transition: 'all 0.3s ease',
	};
});

export const StepWrapper = styled(Stack).attrs({
	spacing: '20px',
	direction: 'row',
})(() => {
	const defaultTheme = useTheme();

	return {
		'&:hover': {
			cursor: 'pointer',

			[StepTitle]: {
				color: defaultTheme.palette.text.primary,
			},
		},
	};
});
