import { createTheme, responsiveFontSizes } from '@mui/material';
import { purple } from '@mui/material/colors';
import { ThemeOptions } from '@mui/material/styles/createTheme';

const themeCommon: ThemeOptions = {
	components: {
		MuiContainer: {
			styleOverrides: {
				root: ({ theme }) => ({
					[theme.breakpoints.down('md')]: {
						paddingLeft: 24,
						paddingRight: 24,
					},
				}),
			},
		},
		MuiSkeleton: {
			defaultProps: {
				animation: 'wave',
			},
			styleOverrides: {
				root: {
					borderRadius: '4px',
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					padding: '10px',
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: '14px',
					background: '#232323',
					maxWidth: 150,
					textAlign: 'center',
					fontWeight: 400,
				},
				arrow: {
					color: '#232323',
				},
			},
		},
	},
};

export const darkTheme = responsiveFontSizes(
	createTheme({
		palette: {
			mode: 'dark',
			primary: {
				main: purple[200],
				light: purple[50],
				dark: purple[400],
			},
		},
		...themeCommon,
	})
);

export const lightTheme = responsiveFontSizes(
	createTheme({
		palette: {
			mode: 'light',
			primary: {
				main: purple[500],
				light: purple[300],
				dark: purple[700],
			},
		},
		...themeCommon,
	})
);
